<template>
  <div class="nav">
    <a-typography-title :level="4">订单</a-typography-title>
    <a-space>
      <a-button @click="exportCk" type="primary" :loading="loading">导出</a-button>
      <a-dropdown>
        <a-button>批量操作</a-button>
        <template #overlay>
          <a-menu>
            <a-menu-item>
              <a-button @click="batchLockCk" type="link" :loading="loading">批量锁定</a-button>
            </a-menu-item>
            <a-menu-item>
              <a-button @click="batchUpdPostNoCk" type="link" :loading="loading">批量修改运单号</a-button>
            </a-menu-item>
            <a-menu-item>
              <a-button @click="batchDeliveryCk" type="link" :loading="loading">批量发货</a-button>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <a-dropdown>
        <a-button>定时任务</a-button>
        <template #overlay>
          <a-menu>
            <a-menu-item>
              <a-button @click="expireCk" type="link" :loading="loading">扫描过期订单</a-button>
            </a-menu-item>
            <a-menu-item>
              <a-button @click="doneAllCk" type="link" :loading="loading">扫描已完成订单</a-button>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <a-button @click="admRefundCk">退货</a-button>
      <a-select class="nav-selector"
                size="middle"
                max-tag-count="responsive"
                :options="orderTypeList"
                v-model:value="pagination.type"
                placeholder="订单类型"
                @change="search"/>
      <a-select class="nav-selector"
                :options="orderStateList"
                v-model:value="pagination.state"
                @change="search"/>
      <a-select class="long-select"
                size="middle"
                mode="multiple"
                max-tag-count="responsive"
                allowClear
                :options="itemList"
                v-model:value="pagination.item"
                placeholder="筛选商品"
                @change="search"/>
      <a-input-search class="nav-search" v-model:value="pagination.search"
                      placeholder="搜索"
                      enter-button
                      allow-clear
                      @search="search"/>
      <a-button @click="query" :loading="loading">刷新</a-button>
    </a-space>
  </div>

  <a-table
      size="small"
      :columns="columns"
      :data-source="source"
      :pagination="pagination"
      :loading="loading"
      :onChange="handlePage"
      :scroll="{ x: 2411 }"
  >
    <template v-slot:bodyCell="{ column, record }">

      <template v-if="column.dataIndex === 'user'">
        [{{ record.userId }}] {{ record.userName }}
      </template>

      <template v-else-if="column.dataIndex === 'orderNo'">
        <div>内部：{{ record.orderNo }}</div>
        <div>支付：{{ record.outOrderNo }}</div>
        <div>商户：{{ record.merchantNo }}</div>
      </template>

      <template v-else-if="column.dataIndex === 'coupon'">
        <template v-if="record.couponList.length < 1">
          -
        </template>
        <a-popover v-else>
          <template #content>
            <a-list size="small" item-layout="vertical" bordered :data-source="record.couponList">
              <template #renderItem="{ item }">
                <a-list-item>{{ item }}</a-list-item>
              </template>
            </a-list>
          </template>
          <a-space>
            <a-tag :bordered="false" color="red" strong>{{ record.coupon }}</a-tag>
            <a-button v-if="record.state === 0" @click="priceCk(record)" size="small">修改</a-button>
          </a-space>
        </a-popover>
      </template>

      <template v-else-if="column.dataIndex === 'payPrice'">

        <a-tag v-if="record.payType === 0" :bordered="false" color="green">微信</a-tag>
        <a-tag v-else :bordered="false" color="blue">支付宝</a-tag>
        {{ record.payPrice }}
      </template>

      <template v-else-if="column.dataIndex === 'item'">
        <a-space>
          <template v-if="record.type === 0">
            <a-tag :bordered="false" color="green">普通</a-tag>
            <div v-for="(v, i) in record.item" :key="i">
              [{{ v.itemId }}] {{ v.itemName }} {{ v.branchName }} × {{ v.count }}
            </div>
          </template>
          <template v-else-if="record.type === 1">
            <a-tag :bordered="false" color="blue">抽卡</a-tag>
            <div v-for="(v, i) in record.item" :key="i">
              [{{ v.itemId }}] {{ v.itemName }} {{ v.branchName }} × {{ v.count }}
            </div>
          </template>
          <template v-else>
            <a-tag :bordered="false" color="orange">发货</a-tag>
            <a-typography-link @click="postViewCk(record)">发货列表</a-typography-link>
          </template>
        </a-space>
      </template>

      <template v-else-if="column.dataIndex === 'postalNo'">
        <a-space>
          <a-space direction="vertical">
            <div>{{ record.postCompany }}</div>
            <div>{{ record.postalNo }}</div>
          </a-space>
          <a-button @click="postCk(record)" size="small">修改</a-button>
        </a-space>
      </template>

      <template v-else-if="column.dataIndex === 'address'">
        <a-space>
          <a-space direction="vertical">
            <div>{{ record.postName }} {{ record.postPhone }}</div>
            <div>{{ record.postArea }}</div>
            <div>{{ record.postAddress }}</div>
          </a-space>
          <a-button @click="addressCk(record)" size="small">修改</a-button>
        </a-space>
      </template>

      <template v-else-if="column.dataIndex === 'transportFee'">
        <a-space>
          <div>{{ record.transportFee }}</div>
          <a-button v-if="record.state === 0" @click="transportFeeCk(record)" size="small">修改</a-button>
        </a-space>
      </template>

      <template v-else-if="column.dataIndex === 'notes'">
        <a-space direction="vertical">
          <a-typography-text v-if="record.notes > 0" type="danger">有备注！</a-typography-text>
          <a-button @click="notesCk(record)" type="dashed" size="small">查看</a-button>
        </a-space>
      </template>

      <template v-else-if="column.dataIndex === 'state'">
        <a-space direction="vertical">
          <a-tag :bordered="false" :color="stateColor(record.state)">{{ record.stateText }}</a-tag>

          <a v-if="record.refundId > 0 && record.refundState > 0" @click="refundViewCk(record)">退款记录</a>
        </a-space>
      </template>

      <template v-else-if="column.dataIndex === 'notify'">
        {{ record.notify === 1 ? '是' : '否' }}
      </template>

      <template v-else-if="column.dataIndex === 'time'">
        <div>创建：{{ record.createTime }}</div>
        <div>支付：{{ record.payTime }}</div>
        <div>发货：{{ record.postTime }}</div>
        <div>完成：{{ record.doneTime }}</div>
      </template>

      <template v-else-if="column.dataIndex === 'act'">
        <a-space>
          <a-button v-if="record.refundState === 0" @click="refundCk(record)" danger size="small">退款</a-button>

          <template v-if="record.state === 1">
            <a-button @click="lockCk(record, 1)" size="small">锁定</a-button>
            <a-button @click="deliveryCk(record)" type="primary" size="small">发货</a-button>
          </template>

          <template v-else-if="record.state === 2">
            <a-button @click="postalCk(record)" size="small">物流</a-button>
            <a-button @click="doneCk(record)" type="primary" size="small">完成</a-button>
          </template>

          <template v-if="record.state === 5">
            <a-button @click="lockCk(record, 0)" size="small">解锁</a-button>
            <a-button @click="deliveryCk(record)" type="primary" size="small">发货</a-button>
          </template>
        </a-space>
      </template>

      <template v-else>
        {{ record[column.dataIndex] }}
      </template>
    </template>
  </a-table>

  <upd-notes-modal ref="updNotes"/>

  <upd-price-modal ref="updPrice" @refresh="query"/>

  <upd-address-modal ref="updAddress" @refresh="query"/>

  <upd-transport-fee-modal ref="updTransportFee" @refresh="query"/>

  <upd-notes-modal ref="updNotes"/>

  <upd-post-no-modal ref="updPostNo" @refresh="query"/>

  <export-modal ref="exportOrder"/>

  <delivery-modal ref="delivery" @refresh="query"/>

  <lock-modal ref="batchLock"/>

  <batch-upd-post-no-modal ref="batchUpdPostNo" @refresh="query"/>

  <postal-modal ref="postal"/>

  <refund-modal ref="refund"/>

  <refund-view-modal ref="refundView"/>

  <post-view-modal ref="postView"/>

  <admin-refund-modal ref="adminRefund"/>
</template>

<script setup>
import {ref} from 'vue'
import {Modal, message} from 'ant-design-vue'
import axios from '@/axios'
// import moment from 'moment'

import UpdPriceModal from '@/views/order/UpdPrice.vue'
import UpdAddressModal from '@/views/order/UpdAddress.vue'
import UpdPostNoModal from '@/views/order/UpdPostNo.vue'
import UpdTransportFeeModal from '@/views/order/UpdTransportFee'
import UpdNotesModal from '@/views/order/UpdNotes.vue'
import ExportModal from '@/views/order/Export.vue'
import LockModal from '@/views/order/Lock.vue'
import DeliveryModal from '@/views/order/Delivery.vue'
import BatchUpdPostNoModal from '@/views/order/BatchUpdPostNo.vue'
import PostalModal from '@/views/order/Postal.vue'
import RefundModal from '@/views/order/Refund.vue'
import RefundViewModal from '@/views/order/RefundView.vue'
import PostViewModal from '@/views/order/PostView.vue'
import AdminRefundModal from '@/views/order/AdminRefund.vue'

const updNotes = ref(null)
const updPrice = ref(null)
const updAddress = ref(null)
const updTransportFee = ref(null)
const updPostNo = ref(null)
const delivery = ref(null)
const batchLock = ref(null)
const batchUpdPostNo = ref(null)
const exportOrder = ref(null)
const postal = ref(null)
const refund = ref(null)
const refundView = ref(null)
const postView = ref(null)
const adminRefund = ref(null)

const source = ref([])
const itemList = ref([])
const loading = ref(false)
const pagination = ref({
  showSizeChanger: true,
  defaultCurrent: 1,
  defaultPageSize: 10,
  current: 1,
  pageSize: 10,
  type: -1,
  state: -8,
  item: [],
  search: '',
  total: 0,
  onShowSizeChange: (current, size) => {
    pagination.value.current = 1
    pagination.value.pageSize = size
    query()
  }
})

const columns = [
  {
    key: 'ID',
    title: 'ID',
    dataIndex: 'id',
  }, {
    key: 'user',
    title: '用户',
    dataIndex: 'user',
  }, {
    key: 'orderNo',
    title: '订单号',
    dataIndex: 'orderNo',
    width: 350,
  }, {
    key: 'item',
    title: '内容',
    dataIndex: 'item',
    width: 500,
  }, {
    key: 'coupon',
    title: '折扣金额 [元]',
    dataIndex: 'coupon',
  }, {
    key: 'payPrice',
    title: '支付金额 [元]',
    dataIndex: 'payPrice',
  }, {
    key: 'address',
    title: '地址',
    dataIndex: 'address',
    width: 360,
  }, {
    key: 'postalNo',
    title: '快递单号',
    dataIndex: 'postalNo',
  }, {
    key: 'transportFee',
    title: '运费 [元]',
    dataIndex: 'transportFee',
  }, {
    key: 'notes',
    title: '备注',
    dataIndex: 'notes',
  }, {
    key: 'state',
    title: '状态',
    dataIndex: 'state',
  }, {
    key: 'time',
    title: '时间',
    dataIndex: 'time',
  }, {
    key: 'act',
    title: '操作',
    dataIndex: 'act',
  }
]
const orderStateList = [
  {value: -9, label: '全部'},
  {value: -8, label: '正常'},
  {value: 0, label: '待支付'},
  {value: 1, label: '已支付'},
  {value: 5, label: '即将发货'},
  {value: 2, label: '已发货'},
  {value: 3, label: '交易完成'},
  {value: -1, label: '过期'},
  {value: -2, label: '取消'},
  {value: 4, label: '交易关闭'},
]
const orderTypeList = [
  {value: -1, label: '全部'},
  {value: 0, label: '普通'},
  {value: 1, label: '抽卡'},
  {value: 2, label: '发货'},
  {value: 3, label: '退款'},
]

function stateColor(state) {
  if (state === -2 || state === -1 || state === 5) {
    return 'red'
  }
  if (state === 0) {
    return 'orange'
  }
  if (state === 2) {
    return 'processing'
  }
  return 'green'
}

function query() {
  loading.value = true

  axios.get('/order', {
    params: {
      page: pagination.value.current,
      size: pagination.value.pageSize,
      type: pagination.value.type,
      state: pagination.value.state,
      item: pagination.value.item,
      search: pagination.value.search,
    }
  }).then((res) => {
    loading.value = false

    if (res.code === 1) {
      pagination.value.current = res.data.page
      pagination.value.total = res.data.total
      source.value = res.data.data
    }
  })
}

function queryItem() {
  axios.get('/order/export-item').then((res) => {
    if (res.code === 1) {
      itemList.value = res.data
    }
  })
}

function search() {
  pagination.value.current = 1

  query()
}

function handlePage(param) {
  pagination.value.current = param.current
  pagination.value.pageSize = param.pageSize
  query()
}

function exportCk() {
  exportOrder.value.openCk()
}

function batchLockCk() {
  batchLock.value.openCk()
}

function batchUpdPostNoCk() {
  batchUpdPostNo.value.openCk()
}

function batchDeliveryCk() {
  delivery.value.openCk()
}

function expireCk() {
  loading.value = true
  axios.post('/order/expire').then((res) => {
    loading.value = false
    if (res.code === 1) {
      message.success('操作成功')
      query()
    }
  })
}

function doneAllCk() {
  loading.value = true
  axios.post('/order/done').then((res) => {
    loading.value = false
    if (res.code === 1) {
      message.success('操作成功')
      query()
    }
  })
}

function doneCk(record) {
  Modal.confirm({
    content: '确定完成吗？',
    okText: '确定',
    cancelText: '取消',
    onOk() {
      axios.post(`/order/${record.id}/done`).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

function priceCk(record) {
  updPrice.value.openCk(record)
}

function addressCk(record) {
  updAddress.value.openCk(record)
}

function transportFeeCk(record) {
  updTransportFee.value.openCk(record)
}

function notesCk(record) {
  updNotes.value.openCk(record)
}

function postCk(record) {
  updPostNo.value.openCk(record)
}

function refundCk(record) {
  refund.value.openCk(record)
}

function lockCk(record, state) {
  Modal.confirm({
    content: state === 1 ? '确定锁定吗？' : '确定解锁吗？',
    okText: '确定',
    cancelText: '取消',
    onOk() {
      axios.post(`/order/${record.id}/lock`, {
        state: state
      }).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

function deliveryCk(record) {
  Modal.confirm({
    content: '确定发货吗？',
    okText: '确定',
    cancelText: '取消',
    onOk() {
      axios.post(`/order/${record.id}/delivery`).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

function postalCk(record) {
  postal.value.openCk(record)
}

function refundViewCk(record) {
  refundView.value.openCk(record)
}

function postViewCk(record) {
  postView.value.openCk(record)
}

function admRefundCk() {
  adminRefund.value.openCk()
}

query()
queryItem()
</script>

<style scoped>
.long-select {
  width: 300px;
}

.item-list {
  max-height: 240px;
  overflow-y: scroll;
}
</style>
