<template>
  <a-modal title="新增"
           :open="modal"
           :width="690"
           :mask-closable="false"
           :confirm-loading="loading"
           @ok="okCk"
           @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :model="entity"
            :rules="rules">
      <a-form-item label="兑换码" name="code" help="多个兑换码用回车隔开">
        <a-textarea :rows="6" v-model:value="entity.code"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'
import {message} from 'ant-design-vue'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const entity = ref({})
const rules = {
  code: [{
    required: true,
    message: '兑换码码不能为空',
    trigger: 'blur'
  }],
}

function openCk() {
  entity.value = {
    code: '',
  }
  modal.value = true
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    const code = ckCode()

    axios.post('/club', {
      code: code,
    }).then((res) => {
      loading.value = false

      if (res.code === 1) {
        message.success('操作成功')
        modal.value = false
        emits('refresh')
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  loading.value = false
}

function ckCode() {
  let data = entity.value.code.split("\n")

  let res = []
  for (const i of data) {
    const line = i.replaceAll('"', '').replaceAll('\t', '').replaceAll(' ', '')
    if (line === '') {
      continue
    }
    res.push(line)
  }
  return res
}

defineExpose({openCk})
</script>
