<template>
  <a-modal title="二维码"
           :open="modal"
           @cancel="cancelCk">
    <div class="qr">
      <p>确保已安装森林进化论app</p>
      <a-image :src="content" :preview="false"/>
    </div>
    <template #footer></template>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import qrcode from 'qrcode'

const modal = ref(false)
const content = ref('')

function openCk(record) {
  const url = record.album < 1 ? ('https://fkmall.mgtv.com/item/' + record.id) : ('https://fkmall.mgtv.com/draw/' + record.id)
  const str = 'forestevo://miniapp?appid=mghlcd3ct8jubsutu&path=' + encodeURIComponent('pages/webview/index?src=' + url)
  qrcode.toDataURL(str, (err, url) => {
    content.value = url
    modal.value = true
  })
}

function cancelCk() {
  modal.value = false
}

defineExpose({openCk})
</script>

<style scoped>
.qr {
  text-align: center;
}
</style>