<template>
  <a-modal title="添加"
           :open="modal"
           :confirm-loading="loading"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :rules="rules"
            :model="entity"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }">
      <a-form-item label="名称" name="name">
        <a-input v-model:value="entity.name"/>
      </a-form-item>

      <a-form-item label="缩略图" name="thumbnail">
        <a-space>
          <a-input v-model:value="entity.thumbnail"/>
          <a-button type="primary" @click="thumbPickCk('thumbnail')">选择</a-button>
        </a-space>
      </a-form-item>

      <a-form-item label="详情样式" name="viewTheme">
        <a-select :options="viewTheme" v-model:value="entity.viewTheme"/>
      </a-form-item>

      <a-form-item label="显示时间">
        <a-switch v-model:checked="entity.showTime"/>
      </a-form-item>

      <template v-if="entity.showTime">
        <a-form-item label="开始时间" help="选择后，点击确定生效">
          <a-date-picker show-time placeholder="开始时间"
                         v-model:value="entity.startTime"/>
        </a-form-item>

        <a-form-item label="结束时间" help="选择后，点击确定生效">
          <a-date-picker show-time placeholder="结束时间"
                         v-model:value="entity.endTime"/>
        </a-form-item>
      </template>
    </a-form>
  </a-modal>

  <thumb-pick-modal ref="thumbPick" @choose="handleChoose"/>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

import ThumbPickModal from '@/components/ThumbPick'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const thumbPick = ref(null)
const entity = ref({
  name: '',
  thumbnail: '',
  viewTheme: 0,
  showTime: false,
  startTime: '',
  endTime: '',
})

const rules = {
  name: [{
    required: true,
    message: '名称不能为空',
    trigger: 'blur'
  }],
  thumbnail: [{
    required: true,
    message: '缩略图不能为空',
    trigger: 'blur'
  }],
}

let viewTheme = []

function openCk() {
  getConf()
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    let data = {...entity.value}
    data.showTime = (data.showTime ? 1 : 0)
    if (data.showTime) {
      data.startTime = data.startTime.format('YYYY-MM-DD HH:mm:ss')
      data.endTime = data.endTime.format('YYYY-MM-DD HH:mm:ss')
    } else {
      data.startTime = '2023-01-01 00:00:00'
      data.endTime = '2033-01-01 00:00:00'
    }

    axios.post('/album', data).then((res) => {
      loading.value = false

      if (res.code === 1) {
        emits('refresh')
        modal.value = false
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  form.value.resetFields()
  loading.value = false
}

function thumbPickCk(key) {
  thumbPick.value.openCk(key)
}

function handleChoose(v, f) {
  if (f === 'thumbnail') {
    entity.value.thumbnail = v
  }
}

function getConf() {
  axios.get('/item-conf').then((res) => {
    if (res.code !== 1) {
      return
    }

    viewTheme = res.data.albumTheme
    if (viewTheme.length) {
      entity.value.viewTheme = viewTheme[0].value
    }
    modal.value = true
  })
}

defineExpose({openCk})
</script>
