<template>
  <div class="nav">
    <a-typography-title :level="4">优惠劵</a-typography-title>
    <a-space>
      <a-button @click="codeCk">兑换码</a-button>
      <a-button type="primary" @click="addCk">添加</a-button>
      <a-input-search v-model:value="search"
                      class="nav-search"
                      placeholder="搜索"
                      enter-button
                      allow-clear
                      @search="query"/>
    </a-space>
  </div>

  <a-table
      size="small"
      :columns="columns"
      :data-source="source"
      :pagination="false"
      :loading="loading"
  >
    <template v-slot:bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'name'">
        {{ record.name }}
        <a-tag color="blue" v-if="record.type === 0">优惠券</a-tag>
        <a-tag color="yellow" v-else-if="record.type === 1">抽卡券</a-tag>
        <a-tag color="orange" v-else>发货券</a-tag>
      </template>

      <template v-else-if="column.dataIndex === 'thumbnail'">
        <a-image v-if="record.thumbnail" :src="record.thumbnail" :width="30" :height="30"/>
      </template>

      <template v-else-if="column.dataIndex === 'item'">
        [{{ record.itemId }}] {{ record.item }}
      </template>

      <template v-else-if="column.dataIndex === 'act'">
        <a-space>
          <a-button @click="usageCk(record)" size="small">使用情况</a-button>
          <a-button type="primary" @click="pubCk(record)" size="small">发放</a-button>
          <a-button @click="updCk(record)" size="small">编辑</a-button>
          <a-button danger @click="delCk(record)" size="small">删除</a-button>
          <a-button danger @click="callbackCk(record)" size="small">召回所有</a-button>
        </a-space>
      </template>

      <template v-else>
        {{ record[column.dataIndex] }}
      </template>
    </template>
  </a-table>

  <add-modal ref="add" @refresh="query"/>

  <upd-modal ref="upd" @refresh="query"/>

  <pub-modal ref="pub"/>

  <ex-code-modal ref="exCode"/>

  <usage-modal ref="usage"/>
</template>

<script setup>
import {ref} from 'vue'
import {Modal, message} from 'ant-design-vue'
import axios from '@/axios'

import AddModal from '@/views/coupon/Add.vue'
import UpdModal from '@/views/coupon/Upd.vue'
import PubModal from '@/views/coupon/Pub.vue'
import ExCodeModal from '@/views/coupon/ExCode.vue'
import UsageModal from '@/views/coupon/Usage.vue'

const add = ref(null)
const upd = ref(null)
const pub = ref(null)
const exCode = ref(null)
const usage = ref(null)
const source = ref([])
const loading = ref(false)
const search = ref('')

const columns = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
  }, {
    key: 'name',
    title: '名称',
    dataIndex: 'name',
  }, {
    key: 'thumbnail',
    title: '缩略图',
    dataIndex: 'thumbnail',
  }, {
    key: 'bottomLine',
    title: '门槛 [元]',
    dataIndex: 'bottomLine',
  }, {
    key: 'price',
    title: '价值 [元]',
    dataIndex: 'price',
  }, {
    key: 'item',
    title: '限定',
    dataIndex: 'item',
  }, {
    key: 'expire',
    title: '有效期 [天]',
    dataIndex: 'expire',
  }, {
    key: 'act',
    title: '操作',
    dataIndex: 'act',
  }
]

function query() {
  loading.value = true

  axios.get('/coupon', {
    search: search.value
  }).then((res) => {
    loading.value = false
    if (res.code === 1) {
      source.value = res.data
    }
  })
}

function addCk() {
  add.value.openCk()
}

function pubCk(record) {
  pub.value.openCk(record)
}

function callbackCk(record) {
  Modal.confirm({
    title: '确定召回吗？',
    content: '将之前发放给用户的优惠劵作废',
    okText: '确定',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      axios.post(`/coupon/${record.id}/callback-all`).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
        }
      })
    },
  })
}

function updCk(record) {
  upd.value.openCk(record)
}

function delCk(record) {
  Modal.confirm({
    content: '确定删除吗？',
    okText: '删除',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      axios.post(`/coupon/${record.id}/state`, {
        state: 0
      }).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

function usageCk(record) {
  usage.value.openCk(record)
}

function codeCk() {
  exCode.value.openCk()
}

query()
</script>
