import Login from '@/views/Login.vue'
import Item from '@/views/item/Item.vue'
import Album from '@/views/album/Album.vue'
import Card from '@/views/card/Card.vue'
import Category from '@/views/category/Category.vue'
import Config from '@/views/config/Config.vue'
import Coupon from '@/views/coupon/Coupon.vue'
import User from '@/views/user/User.vue'
import Order from '@/views/order/Order.vue'
import Upload from '@/views/upload/Upload.vue'

const routes = [
    {
        path: '/login',
        component: Login,
    }, {
        path: '/',
        component: Login,
    }, {
        path: '/category',
        name: 'Category',
        component: Category,
    }, {
        path: '/item',
        name: 'Item',
        component: Item,
    }, {
        path: '/album',
        name: 'Album',
        component: Album,
    }, {
        path: '/card',
        name: 'Card',
        component: Card,
    }, {
        path: '/user',
        name: 'User',
        component: User,
    }, {
        path: '/coupon',
        name: 'Coupon',
        component: Coupon,
    }, {
        path: '/order',
        name: 'Order',
        component: Order,
    }, {
        path: '/upload',
        name: 'Upload',
        component: Upload,
    }, {
        path: '/config',
        name: 'Config',
        component: Config,
    },
]

export default routes