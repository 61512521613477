<template>
  <a-modal title="编辑"
           :open="modal"
           :confirm-loading="loading"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :rules="rules"
            :model="entity"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }">
      <a-form-item label="名称" name="name">
        <a-input v-model:value="entity.name" :maxlength="18"/>
      </a-form-item>

      <a-form-item label="缩略图" name="thumbnail">
        <a-space>
          <a-input v-model:value="entity.thumbnail"/>
          <a-button type="primary" @click="thumbPickCk">选择</a-button>
        </a-space>
      </a-form-item>

      <a-form-item label="类型" name="bottomLine">
        <a-select :options="typeList" v-model:value="entity.type"/>
      </a-form-item>

      <a-form-item label="门槛 [元]" name="bottomLine">
        <a-input v-model:value="entity.bottomLine" min="0"/>
      </a-form-item>

      <a-form-item label="价值 [元]" name="price">
        <a-input-number v-model:value="entity.price" min="0.01"/>
      </a-form-item>

      <a-form-item label="规格" name="item">
        <a-select max-tag-count="responsive"
                  :options="itemList"
                  v-model:value="entity.item"/>
      </a-form-item>

      <a-form-item label="有效期 [天]" name="expire">
        <a-input-number v-model:value="entity.expire" min="1"/>
      </a-form-item>
    </a-form>
  </a-modal>

  <thumb-pick-modal ref="thumbPick" @choose="handleChoose"/>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

import ThumbPickModal from '@/components/ThumbPick'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const thumbPick = ref(null)
const entity = ref({})
const itemList = ref([])

const rules = {
  name: [{
    required: true,
    message: '名称不能为空',
    trigger: 'blur'
  }, {
    max: 26,
    message: '名称长度为1~26位',
    trigger: 'blur'
  }],
  bottomLine: [{
    required: true,
    message: '门槛不能为空',
    trigger: 'blur'
  }],
  price: [{
    required: true,
    message: '价值不能为空',
    trigger: 'blur'
  }],
  expire: [{
    required: true,
    message: '有效期不能为空',
    trigger: 'blur'
  }],
}

const typeList = [
  {
    label: '优惠券',
    value: 0,
  }, {
    label: '抽卡券',
    value: 1,
  }, {
    label: '发货券',
    value: 2,
  }
]

function openCk(record) {
  axios.get('/branch-opt').then((res) => {
    if (res.code === 1) {
      itemList.value = [{label: '通用', options: [{label: '通用', value: 0}]}, ...res.data]
      entity.value = {
        id: record.id,
        name: record.name,
        type: record.type,
        thumbnail: record.thumbnail,
        bottomLine: record.bottomLine,
        price: record.price,
        expire: record.expire,
        item: record.itemId,
      }
      modal.value = true
    }
  })
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    let data = {...entity.value}
    data.bottomLine = data.bottomLine * 100
    data.price = data.price * 100

    axios.put(`/coupon/${entity.value.id}`, data).then((res) => {
      loading.value = false

      if (res.code === 1) {
        emits('refresh')
        modal.value = false
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  form.value.resetFields()
  loading.value = false
}

function thumbPickCk() {
  thumbPick.value.openCk()
}

function handleChoose(v) {
  entity.value.thumbnail = v
}

defineExpose({openCk})
</script>
