<template>
  <a-modal :title="`${itemName} 的规格`"
           :open="modal"
           :width="1200"
           @cancel="cancelCk">
    <a-space class="nav-no-title">
      <a-button v-if="isDraw" @click="analyzeCk" size="small">分析</a-button>
      <a-button type="primary" @click="addCk" size="small">添加</a-button>
      <a-button @click="sortCk" size="small">排序</a-button>
    </a-space>

    <a-table
        size="small"
        :columns="columns"
        :data-source="source"
        :loading="loading"
        :pagination="false"
    >
      <template v-slot:bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'thumbnail'">
          <a-image :src="record.thumbnail" :width="30" :height="30"/>
        </template>

        <template v-else-if="column.dataIndex === 'price'">
          <template v-if="record.discount > 0">
            <del>{{ record.price }}</del>
            <a-divider type="vertical"/>
            <a-typography-text type="danger">{{ record.discount }}</a-typography-text>
          </template>
          <template v-else>
            {{ record.price }}
          </template>
        </template>

        <template v-else-if="column.dataIndex === 'act'">
          <a-space>
            <a-button type="primary" v-if="isDraw" @click="packCk(record)" size="small">卡包</a-button>
            <a-button @click="updCk(record)" size="small">编辑</a-button>
            <a-button danger @click="delCk(record)" size="small">删除</a-button>
          </a-space>
        </template>

        <template v-else>
          {{ record[column.dataIndex] }}
        </template>
      </template>
    </a-table>

    <template #footer></template>
  </a-modal>

  <add-branch-modal ref="add" @refresh="query"/>

  <upd-branch-modal ref="upd" @refresh="query"/>

  <sort-modal ref="sort" @refresh="query"/>

  <pack-modal ref="pack" @refresh="query"/>

  <analyze-modal ref="analyze"/>
</template>

<script setup>
import {ref} from 'vue'
import {message, Modal} from 'ant-design-vue'
import axios from '@/axios'

import AddBranchModal from '@/views/item/AddBranch.vue'
import UpdBranchModal from '@/views/item/UpdBranch.vue'
import AnalyzeModal from '@/views/item/Analyze.vue'
import PackModal from '@/views/item/Pack.vue'
import SortModal from '@/components/Sort.vue'

const emits = defineEmits(['refresh'])

const add = ref(null)
const upd = ref(null)
const sort = ref(null)
const pack = ref(null)
const analyze = ref(null)

const source = ref([])
const modal = ref(false)
const loading = ref(false)
const isDraw = ref(false)

const columns = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
  }, {
    key: 'name',
    title: '名称',
    dataIndex: 'name',
  }, {
    key: 'thumbnail',
    title: '缩略图',
    dataIndex: 'thumbnail',
  }, {
    key: 'describe',
    title: '描述',
    dataIndex: 'describe',
  }, {
    key: 'price',
    title: '价格 [元]',
    dataIndex: 'price',
  }, {
    key: 'unit',
    title: '单位数量',
    dataIndex: 'unit',
  }, {
    key: 'limit',
    title: '限购',
    dataIndex: 'limit',
  }, {
    key: 'stock',
    title: '库存',
    dataIndex: 'stock',
  }, {
    key: 'act',
    title: '操作',
    dataIndex: 'act',
  }
]

let itemId = 0,
    itemName = ''

function query() {
  loading.value = true

  axios.get(`/item/${itemId}/branch`).then((res) => {
    loading.value = false
    if (res.code === 1) {
      source.value = res.data
      modal.value = true
    }
  })
}

function addCk() {
  add.value.openCk(itemId)
}

function sortCk() {
  sort.value.openCk(`/item/${itemId}/branch/sort`)
}

function analyzeCk() {
  analyze.value.openCk(itemId)
}

function packCk(record) {
  pack.value.openCk(record)
}

function updCk(record) {
  upd.value.openCk(itemId, record)
}

function delCk(record) {
  Modal.confirm({
    content: '确定删除吗？',
    okText: '删除',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      axios.post(`/item/${itemId}/branch/${record.id}/state`, {
        state: 0
      }).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

function openCk(record) {
  itemId = record.id
  itemName = record.name
  isDraw.value = (record.album > 0)
  query()
}

function cancelCk() {
  emits('refresh')
  modal.value = false
}

defineExpose({openCk})
</script>
