<template>
  <a-modal title="退款详情"
           :open="modal"
           cancel-text="关闭" @cancel="cancelCk">
    <a-descriptions size="small" :column="1" bordered>
      <a-descriptions-item label="退款单号">{{ entity.no }}</a-descriptions-item>
      <a-descriptions-item label="退款金额">{{ entity.priceFmt }}</a-descriptions-item>
      <a-descriptions-item label="退款数量">{{ entity.count }}</a-descriptions-item>
      <a-descriptions-item label="退款原因">{{ entity.notes }}</a-descriptions-item>
      <a-descriptions-item label="商家反馈">{{ entity.reply }}</a-descriptions-item>
      <a-descriptions-item label="状态">{{ entity.state }}</a-descriptions-item>
      <a-descriptions-item label="最后更新时间">{{ entity.time }}</a-descriptions-item>
    </a-descriptions>
    <template #footer>
      <a-button @click="cancelCk">关闭</a-button>
    </template>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

const modal = ref(false)
const entity = ref({})

function openCk(record) {
  axios.get(`/order/${record.id}/refund`).then((res) => {
    if (res.code === 1) {
      entity.value = res.data
      modal.value = true
    }
  })
}

function cancelCk() {
  modal.value = false
}

defineExpose({openCk})
</script>
