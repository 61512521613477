<template>
  <a-modal title="添加"
           :open="modal"
           :width="800"
           :confirm-loading="loading"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <div class="search-nav">
      <div>
        <a-select size="small" class="category" :options="typeList" v-model:value="entity.type"/>
      </div>
      <a-space>
        <a-select size="small"
                  class="nav-selector"
                  :options="category"
                  v-model:value="param.category"
                  @change="query"/>
        <a-input-search size="small"
                        class="nav-search"
                        placeholder="搜索名称"
                        v-model:value="param.search"
                        :loading="loading"
                        enter-button
                        @search="query"/>
      </a-space>
    </div>
    <a-checkbox-group v-model:value="entity.link">
      <a-checkbox v-for="(v, i) in source" :key="i" :value="v.url">
        <div class="selector">
          <a-image :width="35" :src="v.url"/>
          <a-typography-paragraph class="txt-center" :ellipsis="{ tooltip: v.name }" :content="v.name"/>
        </div>
      </a-checkbox>
    </a-checkbox-group>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const source = ref([])
const category = ref([])
const entity = ref({
  site: 0,
  type: 0,
  link: [],
})
const param = ref({
  search: '',
  category: 0,
})
const typeList = [
  {
    value: 0,
    label: '图片',
  }, {
    value: 1,
    label: '视频',
  },
]

let itemId = 0

function query() {
  if (loading.value) {
    return
  }

  loading.value = true
  axios.get('/upload-ok', {
    params: param.value
  }).then((res) => {
    loading.value = false

    if (res.code === 1) {
      source.value = res.data
    }
  })
}

function categoryOption() {
  axios.get('/upload-category').then((res) => {
    if (res.code === 1) {
      let tmp = [{
        value: 0,
        label: '全部',
      }]
      for (const i of res.data) {
        tmp.push({
          value: i.value,
          label: i.label,
        })
      }
      category.value = tmp
      if (tmp.length) {
        param.value.category = tmp[0].value
      }
    }
  })
}

function openCk(id, site) {
  itemId = id
  entity.value.site = site
  entity.value.link = []
  categoryOption()
  query('')
  modal.value = true
}

function okCk() {
  if (entity.value.link.length < 1) {
    return
  }
  loading.value = true
  axios.post(`/item/${itemId}/thumb`, entity.value).then((res) => {
    loading.value = false

    if (res.code === 1) {
      emits('refresh')
      modal.value = false
    }
  })
}

function cancelCk() {
  modal.value = false
  loading.value = false
}

defineExpose({openCk})
</script>

<style scoped>
.search-nav {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.selector {
  width: 68px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.txt-center {
  text-align: center;
}

:deep(.ant-checkbox-wrapper) {
  align-items: center;
}
</style>
