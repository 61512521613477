<template>
  <a-modal title="编辑"
           :open="modal"
           :confirm-loading="loading"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :rules="rules"
            :model="entity"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }">
      <a-form-item label="类型" name="type">
        <a-select :options="typeList" v-model:value="entity.type"/>
      </a-form-item>

      <a-form-item label="文件" name="link">
        <a-space>
          <a-input v-model:value="entity.link"/>
          <a-button type="primary" @click="thumbPickCk">选择</a-button>
        </a-space>
      </a-form-item>
    </a-form>
  </a-modal>

  <thumb-pick-modal ref="thumbPick" @choose="handleChoose"/>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

import ThumbPickModal from '@/components/ThumbPick'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const thumbPick = ref(null)
const entity = ref({})

const typeList = [
  {
    value: 0,
    label: '图片',
  }, {
    value: 1,
    label: '视频',
  },
]

const rules = {
  link: [{
    required: true,
    message: '文件不能为空',
    trigger: 'blur'
  }],
}

let itemId = 0

function openCk(pid, site, record) {
  itemId = pid

  entity.value = {
    id: record.id,
    site: site,
    type: record.type,
    link: record.link,
  }

  modal.value = true
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    axios.put(`/item/${itemId}/thumb/${entity.value.id}`, entity.value).then((res) => {
      loading.value = false

      if (res.code === 1) {
        emits('refresh')
        modal.value = false
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  form.value.resetFields()
  loading.value = false
}

function thumbPickCk() {
  thumbPick.value.openCk()
}

function handleChoose(v) {
  entity.value.link = v
}

defineExpose({openCk})
</script>
