<template>
  <a-modal title="兑换码"
           :open="modal"
           :width="1200"
           @cancel="cancelCk">
    <a-space class="nav-no-title">
      <a-button type="primary" @click="addCk" size="small">添加</a-button>
    </a-space>

    <a-table
        size="small"
        :columns="columns"
        :data-source="source"
        :loading="loading"
        :pagination="pagination"
        :onChange="handlePage">
      <template v-slot:bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'state'">
          <a-tag v-if="record.state === 1" :bordered="false" color="green">正常</a-tag>
          <a-tag v-else :bordered="false" color="orange">已兑换</a-tag>
        </template>

        <template v-else-if="column.dataIndex === 'act'">
          <a-button danger @click="delCk(record)" size="small">删除</a-button>
        </template>

        <template v-else>
          {{ record[column.dataIndex] }}
        </template>
      </template>
    </a-table>
    <template #footer></template>
  </a-modal>

  <add-modal ref="add" @refresh="query"/>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'
import {message, Modal} from "ant-design-vue"

import AddModal from '@/views/coupon/AddExCode.vue'

const source = ref([])
const modal = ref(false)
const loading = ref(false)
const add = ref(null)
const pagination = ref({
  showSizeChanger: true,
  defaultCurrent: 1,
  defaultPageSize: 10,
  current: 1,
  pageSize: 10,
  total: 0,
  onShowSizeChange: (current, size) => {
    pagination.value.current = 1
    pagination.value.pageSize = size
    query()
  }
})

const columns = [
  {
    key: 'code',
    title: '兑换码',
    dataIndex: 'code',
  }, {
    key: 'state',
    title: '状态',
    dataIndex: 'state',
  }, {
    key: 'time',
    title: '时间',
    dataIndex: 'time',
  }, {
    key: 'act',
    title: '操作',
    dataIndex: 'act',
  }
]

function query() {
  loading.value = true

  axios.get('/ex-code', {
    params: {
      page: pagination.value.current,
      size: pagination.value.pageSize,
    }
  }).then((res) => {
    loading.value = false

    if (res.code === 1) {
      pagination.value.current = res.data.page
      pagination.value.total = res.data.total
      source.value = res.data.data
    }
  })
}

function openCk() {
  query()
  modal.value = true
}

function cancelCk() {
  modal.value = false
}

function handlePage(param) {
  pagination.value.current = param.current
  pagination.value.pageSize = param.pageSize
  query()
}

function addCk() {
  add.value.openCk()
}

function delCk(record) {
  Modal.confirm({
    content: '确定删除吗？',
    okText: '删除',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      axios.post(`/ex-code/${record.id}/state`, {
        state: 0
      }).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

defineExpose({openCk})
</script>
