<template>
  <a-modal title="使用情况"
           :open="modal"
           :width="1200"
           @cancel="cancelCk">
    <a-space class="nav-no-title">
      数量
      <a-tag :bordered="false" color="red">{{ pagination.total }}</a-tag>
      未使用
      <a-tag :bordered="false" color="orange">{{ pagination.unused }}</a-tag>
      <a-input-search v-model:value="pagination.search" size="small"
                      class="nav-search"
                      placeholder="搜索"
                      enter-button
                      allow-clear
                      @search="search"/>
    </a-space>

    <a-table
        size="small"
        :columns="columns"
        :dataSource="source"
        :pagination="pagination"
        :loading="loading"
        :onChange="handlePage"
    >
      <template v-slot:bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'state'">
          <a-space>
            <a-tag v-if="record.state === 0" :bordered="false" color="red">禁用</a-tag>
            <a-tag v-else-if="record.state === 1" :bordered="false">正常</a-tag>
            <a-tag v-else-if="record.state === 2" :bordered="false" color="green">已使用</a-tag>
            <a-tag v-else :bordered="false" color="red">过期</a-tag>
          </a-space>
        </template>

        <template v-else-if="column.dataIndex === 'act'">
          <a-button v-if="record.state === 1" danger @click="callbackCk(record)" size="small">召回</a-button>
        </template>

        <template v-else>
          {{ record[column.dataIndex] }}
        </template>
      </template>
    </a-table>

    <template #footer></template>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'
import {message, Modal} from "ant-design-vue"

const source = ref([])
const modal = ref(false)
const loading = ref(false)
const pagination = ref({
  showSizeChanger: true,
  defaultCurrent: 1,
  defaultPageSize: 10,
  current: 1,
  pageSize: 10,
  search: '',
  total: 0,
  unused: 0,
  onShowSizeChange: (current, size) => {
    pagination.value.current = 1
    pagination.value.pageSize = size
    query()
  }
})

const columns = [
  {
    key: 'userId',
    title: '用户ID',
    dataIndex: 'userId',
  }, {
    key: 'price',
    title: '价值 [元]',
    dataIndex: 'price',
  }, {
    key: 'orderId',
    title: '订单ID',
    dataIndex: 'orderId',
  }, {
    key: 'state',
    title: '状态',
    dataIndex: 'state',
  }, {
    key: 'createTime',
    title: '创建时间',
    dataIndex: 'createTime',
  }, {
    key: 'expireTime',
    title: '过期时间',
    dataIndex: 'expireTime',
  }, {
    key: 'usedTime',
    title: '使用时间',
    dataIndex: 'usedTime',
  }, {
    key: 'act',
    title: '操作',
    dataIndex: 'act',
  }
]

let couponId = 0

function query() {
  loading.value = true

  axios.get(`/coupon/${couponId}/usage`, {
    params: {
      page: pagination.value.current,
      size: pagination.value.pageSize,
      search: pagination.value.search,
    }
  }).then((res) => {
    loading.value = false

    if (res.code === 1) {
      pagination.value.current = res.data.page
      pagination.value.total = res.data.total
      pagination.value.unused = res.data.unused
      source.value = res.data.data
      modal.value = true
    }
  })
}

function handlePage(param) {
  pagination.value.current = param.current
  pagination.value.pageSize = param.pageSize
  query()
}

function openCk(record) {
  couponId = record.id
  query()
}

function cancelCk() {
  modal.value = false
  loading.value = false
}

function search() {
  pagination.value.current = 1
  query()
}

function callbackCk(record) {
  Modal.confirm({
    title: '确定召回吗？',
    content: '将之前发放给用户的优惠劵作废',
    okText: '确定',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      axios.post(`/coupon/${record.id}/callback`).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

defineExpose({openCk})
</script>
