<template>
  <div class="nav">
    <a-typography-title :level="4">分类</a-typography-title>
    <a-space>
      <a-button @click="clearCk">清除缓存</a-button>
      <a-button type="primary" @click="addCk">添加</a-button>
      <a-button @click="sortCk(0)">排序</a-button>
    </a-space>
  </div>

  <a-table
      size="small"
      :columns="columns"
      :data-source="source"
      :pagination="false"
      :loading="loading"
  >
    <template v-slot:bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'thumbnail'">
        <a-image v-if="record.thumbnail" :src="record.thumbnail" :width="30" :height="30"/>
      </template>

      <template v-else-if="column.dataIndex === 'item'">
        <a-space>
          <a-button size="small" type="primary" @click="itemCk(record)">详情</a-button>
          <a-button size="small" @click="sortItemCk(record)">排序</a-button>
        </a-space>
      </template>

      <template v-else-if="column.dataIndex === 'state'">
        <a-space>
          <template v-if="record.state === 1">
            <a-tag :bordered="false">正常</a-tag>
            <a-button size="small" @click="stateCk(record, 2)">上架</a-button>
          </template>
          <template v-else>
            <a-tag color="green" :bordered="false">上架</a-tag>
            <a-button size="small" danger @click="stateCk(record, 1)">下架</a-button>
          </template>
        </a-space>
      </template>

      <template v-else-if="column.dataIndex === 'act'">
        <a-space>
          <a-button @click="updCk(record)" size="small">编辑</a-button>
          <a-button danger @click="delCk(record)" size="small">删除</a-button>
        </a-space>
      </template>

      <template v-else>
        {{ record[column.dataIndex] }}
      </template>
    </template>
  </a-table>

  <add-modal ref="add" @refresh="query"/>

  <upd-modal ref="upd" @refresh="query"/>

  <sort-modal ref="sort" @refresh="query"/>

  <item-modal ref="item"/>
</template>

<script setup>
import {ref} from 'vue'
import {message, Modal} from 'ant-design-vue'
import axios from '@/axios'

import AddModal from '@/views/category/Add.vue'
import SortModal from '@/components/Sort.vue'
import UpdModal from '@/views/category/Upd.vue'
import ItemModal from '@/views/category/Item.vue'

const add = ref(null)
const upd = ref(null)
const sort = ref(null)
const item = ref(null)
const source = ref([])
const loading = ref(false)

const columns = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
  }, {
    key: 'name',
    title: '分类',
    dataIndex: 'name',
  }, {
    key: 'thumbnail',
    title: '缩略图',
    dataIndex: 'thumbnail',
  }, {
    key: 'item',
    title: '商品',
    dataIndex: 'item',
  }, {
    key: 'state',
    title: '状态',
    dataIndex: 'state',
  }, {
    key: 'act',
    title: '操作',
    dataIndex: 'act',
  }
]

function query() {
  loading.value = true

  axios.get('/category').then((res) => {
    loading.value = false
    if (res.code === 1) {
      source.value = res.data
    }
  })
}

function clearCk() {
  axios.delete('/category/cache').then((res) => {
    if (res.code === 1) {
      message.success('操作成功')
    }
  })
}

function addCk() {
  add.value.openCk(0)
}

function sortCk(pid) {
  sort.value.openCk(`/category/sort?pid=${pid}`)
}

function updCk(record) {
  upd.value.openCk(record)
}

function itemCk(record) {
  item.value.openCk(record)
}

function sortItemCk(record) {
  sort.value.openCk(`/category/${record.id}/item/sort`)
}

function stateCk(record, state) {
  Modal.confirm({
    content: '确定' + (state === 1 ? '下架' : '上架') + '吗？',
    okText: '确定',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      axios.post(`/category/${record.id}/state`, {
        state: state
      }).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

function delCk(record) {
  Modal.confirm({
    content: '确定删除吗？',
    okText: '删除',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      axios.post(`/category/${record.id}/state`, {
        state: 0
      }).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

query()
</script>
