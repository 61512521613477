<template>
  <a-modal title="批量添加卡包"
           :open="modal"
           :destroy-on-close="true"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form size="small"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }">
      <a-form-item label="数量" name="num">
        <a-input-number min="1" v-model:value="num"/>
      </a-form-item>

    </a-form>
    <template #footer>
      <a-button type="primary" @click="okCk">添加</a-button>
    </template>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'
import {Modal} from "ant-design-vue"

const emits = defineEmits(['refresh'])

const modal = ref(false)
const num = ref(0)

let branchId

function okCk() {
  axios.post(`/branch/${branchId}/batch-pack`, {
    num: num.value
  }).then((res) => {
    if (res.code === 1) {
      Modal.success({
        title: '完成',
        content: '成功添加了：' + res.data,
        okText: '我知道了',
        onOk: () => {
          emits('refresh')
          modal.value = false
        }
      })
    }
  })
}

function openCk(id) {
  branchId = id
  num.value = 0
  modal.value = true
}

function cancelCk() {
  modal.value = false
}

defineExpose({openCk})
</script>