<template>
  <a-modal title="排序"
           :open="modal"
           :width="800"
           :mask-closable="false"
           :confirm-loading="loading"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-table
        size="small"
        :custom-row="sortRow"
        :row-class-name="dragOverCls"
        :scroll="{y: 500}"
        :columns="columns"
        :data-source="source"
        :loading="loading"
        :pagination="false"
    />
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const source = ref([])
const dragObj = ref({})
const dragOverIndex = ref(-1)

const columns = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
  }, {
    key: 'name',
    title: '名称',
    dataIndex: 'name',
  }, {
    key: 'sort',
    title: '排序',
    dataIndex: 'sort',
  }
]

let url = '',
    dragIndex = 0,
    dropIndex = 0

function openCk(target) {
  url = target

  loading.value = true

  axios.get(url).then((res) => {
    loading.value = false

    if (res.code === 1) {
      source.value = res.data
      modal.value = true
    }
  })
}

function okCk() {
  loading.value = true

  let id = []
  for (const i of source.value) {
    id.push(i.id)
  }

  axios.post(url, {
    id: id
  }).then((res) => {
    loading.value = false

    if (res.code === 1) {
      emits('refresh')
      modal.value = false
    }
  })
}

function cancelCk() {
  modal.value = false
  loading.value = false
}

function sortRow(record, index) {
  return {
    style: {
      cursor: 'move',
    },
    onMouseenter: event => {
      event.target.draggable = true
    },
    onMouseout: event => {
      event.target.draggable = false
    },
    onDragstart: event => {
      event.stopPropagation()
      dragObj.value = record
      dragIndex = index
    },
    onDragover: event => {
      event.preventDefault()
      event.dataTransfer.dropEffect = 'move'
      dropIndex = index
    },
    ondragenter: event => {
      event.preventDefault()
      dragOverIndex.value = index
    },
    onDrop: event => {
      event.stopPropagation()
      dropIndex = index
      dragOverIndex.value = -1
      if (dropIndex !== dragIndex) {
        source.value.splice(dragIndex, 1)
        source.value.splice(dropIndex, 0, dragObj.value)
      }
    }
  }
}

function dragOverCls(record, index) {
  return dragOverIndex.value === index ? 'drag-over' : ''
}

defineExpose({openCk})
</script>

<style scoped>
:deep(.drag-over > td) {
  border-top: 1px solid orange !important;
}
</style>