<template>
  <a-space class="nav-no-title">
    <a-button @click="clearCk(0)" :loading="loading">清除缓存</a-button>
    <a-button type="primary" @click="addCk">添加</a-button>
    <a-button @click="sortCk">排序</a-button>
  </a-space>

  <a-table
      size="small"
      :columns="columns"
      :data-source="source"
      :loading="loading"
      :pagination="false"
  >
    <template v-slot:bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'thumbnail'">
        <a-image v-if="record.thumbnail" :src="record.thumbnail" :width="30" :height="30"/>
      </template>

      <template v-else-if="column.dataIndex === 'showTime'">
        <a-tag v-if="record.showTime" :bordered="false" color="green">显示</a-tag>
        <a-tag v-else :bordered="false" color="red">不显示</a-tag>
      </template>

      <template v-else-if="column.dataIndex === 'time'">
        <template v-if="record.showTime">
          <div>{{ record.startTime }} ~</div>
          <div>{{ record.endTime }}</div>
        </template>
      </template>

      <template v-else-if="column.dataIndex === 'state'">
        <a-space>
          <template v-if="record.state === 1">
            <a-tag :bordered="false">正常</a-tag>
            <a-button v-if="store.state.auth.group === 0" size="small" @click="stateCk(record, 2)">上架</a-button>
          </template>
          <template v-else>
            <a-tag :bordered="false" color="green">上架</a-tag>
            <a-button v-if="store.state.auth.group === 0" size="small" danger @click="stateCk(record, 1)">下架</a-button>
          </template>
        </a-space>
      </template>

      <template v-else-if="column.dataIndex === 'act'">
        <a-space>
          <a-button type="dashed" @click="clearCk(record.id)" size="small">清除缓存</a-button>
          <a-button @click="updCk(record)" size="small">编辑</a-button>
          <a-button danger @click="delCk(record)" size="small">删除</a-button>
        </a-space>
      </template>

      <template v-else>
        {{ record[column.dataIndex] }}
      </template>
    </template>
  </a-table>

  <add-modal ref="add" @refresh="query"/>

  <upd-modal ref="upd" @refresh="query"/>

  <sort-modal ref="sort" @refresh="query"/>
</template>

<script setup>
import {ref} from 'vue'
import {message, Modal} from 'ant-design-vue'
import {useStore} from 'vuex'
import axios from '@/axios'

import AddModal from '@/views/album/Add.vue'
import UpdModal from '@/views/album/Upd.vue'
import SortModal from '@/components/Sort.vue'

const store = useStore()

const loading = ref(false)

const add = ref(null)
const upd = ref(null)
const sort = ref(null)

const source = ref([])

const columns = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
  }, {
    key: 'name',
    title: '名称',
    dataIndex: 'name',
  }, {
    key: 'thumbnail',
    title: '缩略图',
    dataIndex: 'thumbnail',
  }, {
    key: 'showTime',
    title: '显示时间',
    dataIndex: 'showTime',
  }, {
    key: 'time',
    title: '时间',
    dataIndex: 'time',
    width: 180,
  }, {
    key: 'state',
    title: '状态',
    dataIndex: 'state',
  }, {
    key: 'act',
    title: '操作',
    dataIndex: 'act',
  }
]

function query() {
  loading.value = true
  axios.get('/album').then((res) => {
    if (res.code === 1) {
      source.value = res.data
      loading.value = false
    }
  })
}

function addCk() {
  add.value.openCk()
}

function sortCk() {
  sort.value.openCk('/album/sort')
}

function updCk(record) {
  upd.value.openCk(record)
}

function delCk(record) {
  Modal.confirm({
    content: '确定删除吗？',
    okText: '删除',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      axios.post(`/album/${record.id}/state`, {
        state: 0
      }).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

function stateCk(record, state) {
  Modal.confirm({
    content: '确定' + (state === 1 ? '下架' : '上架') + '吗？',
    okText: '继续',
    cancelText: '取消',
    onOk() {
      axios.post(`/album/${record.id}/state`, {
        state: state
      }).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

function clearCk(id) {
  axios.delete(`/album/${id}`).then((res) => {
    if (res.code === 1) {
      message.success('操作成功')
    }
  })
}

query()
</script>
