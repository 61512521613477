<template>
  <a-modal title="商品分类"
           :open="modal"
           :width="690"
           :mask-closable="false"
           :confirm-loading="loading"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-transfer
        :titles="['不在分类中', '已在分类中']"
        :selected-keys="selectKey"
        :target-keys="targetKey"
        :data-source="source"
        :render="(item) => item.title"
        show-search
        :filter-option="filterOpt"
        :onSelectChange="onSelectChange"
        :onChange="onChange"
        :list-style="{
          width: '300px',
          height: '600px'
        }"
    />
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

const source = ref([])
const selectKey = ref([])
const targetKey = ref([])
const modal = ref(false)
const loading = ref(false)

let categoryId = 0

function openCk(record) {
  categoryId = record.id

  axios.get(`/category/${categoryId}/item`).then((res) => {
    loading.value = false

    if (res.code === 1) {
      source.value = res.data.source
      targetKey.value = res.data.targetKey
      modal.value = true
    }
  })
}

function onSelectChange(sourceKey, targetKey) {
  selectKey.value = [...sourceKey, ...targetKey]
}

function onChange(nextTargetKey) {
  targetKey.value = nextTargetKey
}

function filterOpt(input, option) {
  return option.key.toString() === input || option.title.indexOf(input) > -1
}

function okCk() {
  axios.post(`/category/${categoryId}/item`, {
    itemId: targetKey.value
  }).then((res) => {
    loading.value = false

    if (res.code === 1) {
      modal.value = false
    }
  })
}

function cancelCk() {
  modal.value = false
}

defineExpose({openCk})
</script>
