<template>
  <a-modal title="上传"
           :open="modal"
           :confirmLoading="loading"
           cancel-text="关闭" @cancel="cancelCk">
    <div class="nav-el">
      <a-select size="small" class="nav-selector" :options="category" v-model:value="current"/>
    </div>
    <a-upload-dragger v-model:fileList="uploadList"
                      accept="image/*"
                      :multiple="true"
                      :max-count="9"
                      @drag="clear"
                      :customRequest="upload">
      <p class="ant-upload-text">点击 或 拖动图片到这里</p>
      <p class="ant-upload-hint">
        同时最多可上传9张<br/>
        单张图片大小限制在 <b class="hit">2M</b> 以内
      </p>
    </a-upload-dragger>
    <a-descriptions class="mt10" size="small" :column="1" bordered title="推荐尺寸">
      <a-descriptions-item label="商品缩略图">正方形</a-descriptions-item>
      <a-descriptions-item label="商品头图">750 × 750</a-descriptions-item>
      <a-descriptions-item label="商品规则">宽度相同即可，如果图片较大，建议切成若干小图</a-descriptions-item>
      <a-descriptions-item label="商品详情">宽度相同即可，如果图片较大，建议切成若干小图</a-descriptions-item>
      <a-descriptions-item label="规格缩略图">正方形</a-descriptions-item>
      <a-descriptions-item label="解锁项">120 × 120，前端会强制截成圆形，请保证内容在图片中央</a-descriptions-item>
      <a-descriptions-item label="客服二维码">若有多个二维码，请拼接成一张图</a-descriptions-item>
    </a-descriptions>
    <template #footer>
      <a-button @click="cancelCk">关闭</a-button>
    </template>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'
import {message} from 'ant-design-vue'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const category = ref([])
const uploadList = ref([])
const current = ref(0)

function openCk() {
  axios.get('/upload-category').then((res) => {
    if (res.code !== 1) {
      return
    }
    let tmp = []
    for (const i of res.data) {
      tmp.push({
        value: i.value,
        label: i.label,
      })
    }
    category.value = tmp
    if (tmp.length) {
      current.value = tmp[0].value
    }
    modal.value = true
  })
}

function upload(info) {
  info.status = 'uploading'

  const form = new FormData()
  form.append('file', info.file)
  form.append('category', current.value)

  axios.post('/upload', form).then((res) => {
    if (res.code === 1 && res.data.state === 2) {
      info.onSuccess(res, info.file)
      info.status = 'done'
      return
    }

    info.onError()
    message.error(`${info.file.name} 上传失败：${res.msg}`)
    info.status = 'error'
  })
}

function clear() {
  uploadList.value = []
}

function cancelCk() {
  modal.value = false
  loading.value = false
  clear()
  emits('refresh')
}

defineExpose({openCk})
</script>

<style scoped>
.nav-el {
  text-align: right;
  margin-bottom: 10px;
}

.hit {
  color: #FF0000;
}

.mt10 {
  margin-top: 10px;
}
</style>