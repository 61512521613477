<template>
  <a-modal title="修改快递单号"
           :open="modal"
           :confirm-loading="loading"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :rules="rules"
            :model="entity"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }">
      <a-form-item label="快递公司">
        <a-select :options="company" v-model:value="entity.postCompany"/>
      </a-form-item>
      <a-form-item label="快递单号" name="postNo">
        <a-input v-model:value="entity.postNo"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const entity = ref({})
const rules = {
  postNo: [{
    required: true,
    message: '快递单号不能为空',
    trigger: 'blur'
  }],
}

let company = []

function openCk(record) {
  axios.get('/post-company').then((res) => {
    if (res.code === 1) {
      company = res.data

      entity.value = {
        id: record.id,
        postCompany: val2id(record.postCompany),
        postNo: record.postalNo !== '-' ? record.postalNo : '',
      }

      modal.value = true
    }
  })
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    axios.post(`/order/${entity.value.id}/post-no`, entity.value).then((res) => {
      loading.value = false

      if (res.code === 1) {
        emits('refresh')
        modal.value = false
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  form.value.resetFields()
  loading.value = false
}

function val2id(v) {
  for (const i of company) {
    if (i.label === v) {
      return i.value
    }
  }
  return company[0].value
}

defineExpose({openCk})
</script>
