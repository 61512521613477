<template>
  <a-modal title="退货"
           :open="modal"
           :confirm-loading="loading"
           :mask-closable="false"
           ok-text="提交" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :model="entity"
            :rules="rules"
            :labelCol="{ span: 5 }"
            :wrapperCol="{ span: 19 }">
      <a-form-item label="内部订单号" name="orderNo">
        <a-input v-model:value="entity.orderNo"/>
      </a-form-item>
      <a-form-item label="退款金额[元]" name="price" help="若不退邮费，请自行扣除">
        <a-input-number v-model:value="entity.price" min="0.01"/>
      </a-form-item>
      <a-form-item label="退货运单号" name="postNo">
        <a-input v-model:value="entity.postNo"/>
      </a-form-item>
      <a-form-item label="退货原因" name="reason">
        <a-textarea :rows="8" show-count :maxLength="1000" v-model:value="entity.reason"/>
      </a-form-item>
      <a-form-item label="密码" name="pwd">
        <a-input-password v-model:value="entity.pwd"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'
import {Modal} from 'ant-design-vue'

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const entity = ref({})

const rules = {
  orderNo: [{
    required: true,
    message: '订单号不能为空',
    trigger: 'blur'
  }],
  price: [{
    required: true,
    message: '退款金额不能为空',
    trigger: 'blur'
  }],
  postNo: [{
    required: true,
    message: '退货运单号不能为空',
    trigger: 'blur'
  }],
  reason: [{
    required: true,
    message: '退货原因不能为空',
    trigger: 'blur'
  }],
  pwd: [{
    required: true,
    message: '密码不能为空',
    trigger: 'blur'
  }],
}

function openCk() {
  entity.value = {}
  modal.value = true
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    axios.post('/order/refund', {
      orderNo: entity.value.orderNo,
      price: entity.value.price * 100,
      postNo: entity.value.postNo,
      reason: entity.value.reason,
      pwd: entity.value.pwd,
    }).then((res) => {
      loading.value = false

      if (res.code === 1) {
        Modal.success({
          title: '退款成功',
          content: '请求已经提交到支付系统，这个过程可能需要几分钟，请稍后刷新页面查看结果',
          okText: '我知道了',
          onOk: () => {
            modal.value = false
          }
        })
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  form.value.resetFields()
  loading.value = false
}

defineExpose({openCk})
</script>
