<template>
  <a-space class="nav-no-title">
    <a-select class="long-select"
              :options="albumList"
              v-model:value="k"
              show-search
              @change="query"/>
    <a-button type="primary" @click="addCk">添加</a-button>
    <a-button @click="sortCk">排序</a-button>
    <a-button @click="query" :loading="loading">刷新</a-button>
  </a-space>

  <a-table
      size="small"
      :columns="columns"
      :data-source="source"
      :loading="loading"
      :pagination="false"
  >
    <template v-slot:bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'frontThumb'">
        <a-image :src="record.frontThumb" :height="30"/>
      </template>

      <template v-else-if="column.dataIndex === 'backThumb'">
        <a-image :src="record.backThumb" :height="30"/>
      </template>

      <template v-else-if="column.dataIndex === 'state'">
        <a-space>
          <template v-if="record.state === 1">
            <a-tag :bordered="false">正常</a-tag>
            <a-button v-if="store.state.auth.group === 0" size="small" @click="stateCk(record, 2)">上架</a-button>
          </template>
          <template v-else>
            <a-tag :bordered="false" color="green">上架</a-tag>
            <a-button v-if="store.state.auth.group === 0" size="small" danger @click="stateCk(record, 1)">下架</a-button>
          </template>
        </a-space>
      </template>

      <template v-else-if="column.dataIndex === 'act'">
        <a-space>
          <a-button @click="updCk(record)" size="small">编辑</a-button>
          <a-button danger @click="delCk(record)" size="small">删除</a-button>
        </a-space>
      </template>

      <template v-else>
        {{ record[column.dataIndex] }}
      </template>
    </template>
  </a-table>

  <add-modal ref="add" @refresh="query"/>

  <upd-modal ref="upd" @refresh="query"/>

  <sort-modal ref="sort" @refresh="query"/>
</template>

<script setup>
import {ref} from 'vue'
import {message, Modal} from 'ant-design-vue'
import {useStore} from 'vuex'
import axios from '@/axios'

import AddModal from '@/views/card/Add.vue'
import UpdModal from '@/views/card/Upd.vue'
import SortModal from '@/components/Sort.vue'

const store = useStore()

const k = ref(0)

const loading = ref(false)

const add = ref(null)
const upd = ref(null)
const sort = ref(null)

const albumList = ref([])
const source = ref([])

const columns = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
  }, {
    key: 'name',
    title: '名称',
    dataIndex: 'name',
  }, {
    key: 'frontThumb',
    title: '正面',
    dataIndex: 'frontThumb',
  }, {
    key: 'backThumb',
    title: '背面',
    dataIndex: 'backThumb',
  }, {
    key: 'state',
    title: '状态',
    dataIndex: 'state',
  }, {
    key: 'rare',
    title: '稀有度',
    dataIndex: 'rare',
  }, {
    key: 'stock',
    title: '库存',
    dataIndex: 'stock',
  }, {
    key: 'act',
    title: '操作',
    dataIndex: 'act',
  }
]

function query() {
  loading.value = true
  axios.get('/card', {
    params: {
      album: k.value
    }
  }).then((res) => {
    if (res.code === 1) {
      source.value = res.data
      loading.value = false
    }
  })
}

function option() {
  axios.get('/album-opt').then((res) => {
    if (res.code === 1) {
      albumList.value = res.data
      if (albumList.value.length) {
        k.value = albumList.value[0].value
      }
      query()
    }
  })
}

function addCk() {
  add.value.openCk(k.value)
}

function sortCk() {
  sort.value.openCk(`/card/sort?album=${k.value}`)
}

function updCk(record) {
  upd.value.openCk(k.value, record)
}

function delCk(record) {
  Modal.confirm({
    content: '确定删除吗？',
    okText: '删除',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      axios.post(`/card/${record.id}/state`, {
        state: 0
      }).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

function stateCk(record, state) {
  Modal.confirm({
    content: '确定' + (state === 1 ? '下架' : '上架') + '吗？',
    okText: '继续',
    cancelText: '取消',
    onOk() {
      axios.post(`/card/${record.id}/state`, {
        state: state
      }).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

option()
</script>
