<template>
  <a-modal title="备注"
           :open="modal"
           :confirm-loading="loading"
           :mask-closable="false"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :model="entity"
            :rules="rules">
      <a-form-item name="notes">
        <a-textarea :rows="8" show-count :maxLength="1000" v-model:value="entity.notes"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const entity = ref({})

const rules = {
  notes: [{
    required: true,
    message: '备注不能为空',
    trigger: 'blur'
  }]
}

let orderId = 0

function openCk(record) {
  orderId = record.id

  axios.get(`/order/${record.id}/notes`).then((res) => {
    loading.value = false

    if (res.code === 1) {
      entity.value = {
        notes: res.data.notes
      }
      modal.value = true
    }
  })
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    axios.post(`/order/${orderId}/notes`, entity.value).then((res) => {
      loading.value = false

      if (res.code === 1) {
        modal.value = false
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  form.value.resetFields()
  loading.value = false
}

defineExpose({openCk})
</script>
