<template>
  <a-modal title="发货列表"
           :open="modal"
           @cancel="cancelCk">
    <a-space class="nav-no-title">
      总数
      <a-tag :bordered="false" color="red">{{ count }}</a-tag>
    </a-space>

    <a-table
        size="small"
        :columns="columns"
        :data-source="source"
        :loading="loading"
        :pagination="false"
        :scroll="{ y: 611 }">
      <template v-slot:bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'thumb'">
          <a-image :src="record.thumb" :height="30"/>
        </template>

        <template v-else>
          {{ record[column.dataIndex] }}
        </template>
      </template>
    </a-table>
    <template #footer></template>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

const source = ref([])

const count = ref(0)

const modal = ref(false)
const loading = ref(false)

const columns = [
  {
    key: 'name',
    title: '名称',
    dataIndex: 'name',
  }, {
    key: 'thumb',
    title: '正面',
    dataIndex: 'thumb',
  }, {
    key: 'count',
    title: '数量',
    dataIndex: 'count',
  },
]

function query(id) {
  loading.value = true

  axios.get(`/order/${id}/post-card`,).then((res) => {
    loading.value = false

    if (res.code === 1) {
      source.value = res.data

      let tmp = 0
      for (const i of res.data) {
        tmp += i.count
      }
      count.value = tmp
    }
  })
}

function openCk(record) {
  query(record.id)
  modal.value = true
}

function cancelCk() {
  modal.value = false
}

defineExpose({openCk})
</script>