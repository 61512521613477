<template>
  <a-modal title="添加"
           :open="modal"
           :confirm-loading="loading"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :rules="rules"
            :model="entity"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }">
      <a-form-item label="名称" name="name">
        <a-input v-model:value="entity.name" :maxlength="32"/>
      </a-form-item>

      <a-form-item label="缩略图" name="thumbnail">
        <a-space>
          <a-input v-model:value="entity.thumbnail"/>
          <a-button type="primary" @click="thumbPickCk">选择</a-button>
        </a-space>
      </a-form-item>

      <a-form-item label="描述" name="describe">
        <a-textarea v-model:value="entity.describe" :maxlength="38"/>
      </a-form-item>

      <a-form-item label="价格 [元]" name="price">
        <a-input-number min="0.01" v-model:value="entity.price"/>
      </a-form-item>

      <a-form-item label="单位数量" name="unit">
        <a-input-number min="0" v-model:value="entity.unit"/>
      </a-form-item>

      <!--      <a-form-item label="优惠价 [元]" name="discount">-->
      <!--        <a-input-number :min="0" v-model:value="entity.discount"/>-->
      <!--      </a-form-item>-->

      <a-form-item label="限购" name="limit">
        <a-input-number min="0" v-model:value="entity.limit"/>
      </a-form-item>

      <a-form-item label="库存" name="stock">
        <a-input-number min="0" v-model:value="entity.stock"/>
      </a-form-item>
    </a-form>
  </a-modal>

  <thumb-pick-modal ref="thumbPick" @choose="handleChoose"/>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

import ThumbPickModal from '@/components/ThumbPick'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const thumbPick = ref(null)
const entity = ref({
  name: '',
  thumbnail: '',
  describe: '',
  price: 1,
  discount: 0,
  limit: 0,
  stock: 0,
  unit: 0,
})

const rules = {
  name: [{
    required: true,
    message: '名称不能为空',
    trigger: 'blur'
  }, {
    max: 32,
    message: '名称长度为1~32位',
    trigger: 'blur'
  }],
  thumbnail: [{
    required: true,
    message: '缩略图不能为空',
    trigger: 'blur'
  }],
  describe: [{
    max: 38,
    message: '描述最长为38位',
    trigger: 'blur'
  }],
  price: [{
    required: true,
    message: '价格不能为空',
    trigger: 'blur'
  }],
  discount: [{
    required: true,
    message: '优惠价不能为空',
    trigger: 'blur'
  }],
  limit: [{
    required: true,
    message: '限购不能为空',
    trigger: 'blur'
  }],
  stock: [{
    required: true,
    message: '库存不能为空',
    trigger: 'blur'
  }],
  unit: [{
    required: true,
    message: '单位数量不能为空',
    trigger: 'blur'
  }],
}

let itemId = 0

function openCk(id) {
  itemId = id
  modal.value = true
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    let data = {...entity.value}
    data.price = data.price * 100

    axios.post(`/item/${itemId}/branch`, data).then((res) => {
      loading.value = false

      if (res.code === 1) {
        emits('refresh')
        modal.value = false
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  form.value.resetFields()
  loading.value = false
}

function thumbPickCk() {
  thumbPick.value.openCk()
}

function handleChoose(v) {
  entity.value.thumbnail = v
}

defineExpose({openCk})
</script>
