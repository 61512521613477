<template>
  <a-modal title="搜索记录"
           :open="modal"
           :width="800"
           @cancel="cancelCk">
    <a-table
        size="small"
        :columns="columns"
        :data-source="source"
        :loading="loading"
        :pagination="pagination"
        :onChange="handlePage"
    />
    <template #footer></template>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

const source = ref([])
const modal = ref(false)
const loading = ref(false)
const pagination = ref({
  showSizeChanger: true,
  defaultCurrent: 1,
  defaultPageSize: 10,
  current: 1,
  pageSize: 10,
  total: 0,
  onShowSizeChange: (current, size) => {
    pagination.value.current = 1
    pagination.value.pageSize = size
    query()
  }
})

const columns = [
  {
    key: 'text',
    title: '关键词',
    dataIndex: 'text',
  }, {
    key: 'count',
    title: '次数',
    dataIndex: 'count',
  }, {
    key: 'updateTime',
    title: '最后搜索时间',
    dataIndex: 'updateTime',
  },
]

function query() {
  loading.value = true

  axios.get('/log/search', {
    params: {
      page: pagination.value.current,
      size: pagination.value.pageSize,
    }
  }).then((res) => {
    loading.value = false

    if (res.code === 1) {
      pagination.value.current = res.data.page
      pagination.value.total = res.data.total
      source.value = res.data.data
    }
  })
}

function openCk() {
  query()
  modal.value = true
}

function cancelCk() {
  modal.value = false
}

function handlePage(param) {
  pagination.value.current = param.current
  pagination.value.pageSize = param.pageSize
  query()
}

defineExpose({openCk})
</script>
