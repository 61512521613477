<template>
  <a-modal title="分析"
           :width="880"
           :open="modal"
           @cancel="cancelCk">
    <div class="wrap">
      <a-tabs v-model:activeKey="active" @change="onChange">
        <a-tab-pane key="0" tab="总览">
          <div id="totalPool" class="canvas"></div>
          <div id="totalPoolPie" class="canvas"></div>

          <div id="totalSold" class="canvas"></div>
          <div id="totalSoldPie" class="canvas"></div>
        </a-tab-pane>
        <a-tab-pane v-for="v in tabs" :key="v" :tab="`规格${v}`">
          <div :id="`pool${v}`" class="canvas"></div>
          <div :id="`poolPie${v}`" class="canvas"></div>

          <div :id="`sold${v}`" class="canvas"></div>
          <div :id="`soldPie${v}`" class="canvas"></div>
        </a-tab-pane>
      </a-tabs>
    </div>
    <template #footer></template>
  </a-modal>
</template>

<script setup>
import {nextTick, ref} from 'vue'
import axios from '@/axios'
import * as echarts from "echarts"

const modal = ref(false)
const active = ref('0')
const tabs = ref([])
const source = ref({})

let itemId

function query() {
  axios.get(`/item/${itemId}/analyze`).then((res) => {
    if (res.code === 1) {
      modal.value = true
      source.value = res.data

      const tmp = []
      for (const i in res.data.branch) {
        tmp.push(i)
      }
      tabs.value = tmp

      onChange()
    }
  })
}

function onChange() {
  if (active.value === '0') {
    const totalPoolNum = sum(source.value.total.pool)
    const totalSoldNum = sum(source.value.total.sold)
    nextTick(() => {
      mkBar(`卡池（${totalPoolNum}）`, 'totalPool', source.value.total.pool)
      mkPie('totalPoolPie', source.value.total.pool)

      mkBar(`售出（${totalSoldNum}）`, 'totalSold', source.value.total.sold)
      mkPie('totalSoldPie', source.value.total.sold)
    })
    return
  }

  const index = parseInt(active.value)
  const branchPoolNum = sum(source.value.branch[index].pool)
  console.log(source.value.branch)
  const branchSoldNum = sum(source.value.branch[index].sold)
  nextTick(() => {
    mkBar(`卡池（${branchPoolNum}）`, `pool${index}`, source.value.branch[index].pool)
    mkPie(`poolPie${index}`, source.value.branch[index].pool)

    mkBar(`售出（${branchSoldNum}）`, `sold${index}`, source.value.branch[index].sold)
    mkPie(`soldPie${index}`, source.value.branch[index].sold)
  })
}

function sum(list) {
  let res = 0
  for (const i of list) {
    res += i.value
  }
  return res
}

function mkBar(title, el, data) {
  let cate = [], num = []
  for (const i of data) {
    cate.push(i.name)
    num.push(i.value)
  }
  const option = {
    title: {
      text: title,
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    xAxis: {
      type: 'category',
      data: cate,
    },
    yAxis: {
      type: 'value'
    },
    series: [{
      type: 'bar',
      data: num,
    }]
  }
  const chart = echarts.init(document.getElementById(el))
  chart.setOption(option)
}

function mkPie(el, data) {
  const option = {
    tooltip: {
      trigger: 'item',
      formatter: '{c} ({d}%)'
    },
    series: [{
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      emphasis: {
        label: {
          show: true,
          fontSize: 20,
        }
      },
      data: data
    }]
  }
  const chart = echarts.init(document.getElementById(el))
  chart.setOption(option)
}

function openCk(id) {
  modal.value = true
  active.value = '0'
  itemId = id
  query()
}

function cancelCk() {
  modal.value = false
}

defineExpose({openCk})
</script>>

<style scoped>
.wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 20px 20px 0;
}

:deep(.ant-tabs) {
  width: 100%;
}

.canvas {
  width: 400px;
  height: 300px;
  display: inline-flex;
}
</style>