<template>
  <a-modal title="会员月卡"
           :open="modal"
           :width="1200"
           @cancel="cancelCk">
    <a-space class="nav-no-title">
      <div>剩余
        <a-tag :bordered="false" color="red">{{ remain }}</a-tag>
      </div>
      <a-button type="primary" @click="addCk" size="small">添加</a-button>
      <a-button @click="assignCk" size="small" :loading="loading">发放</a-button>
      <a-button @click="reassignCk" size="small" :loading="loading">补偿</a-button>
      <a-input-search v-model:value="pagination.search" size="small"
                      class="nav-search"
                      placeholder="搜索"
                      enter-button
                      allow-clear
                      @search="search"/>
    </a-space>

    <a-table
        size="small"
        :columns="columns"
        :data-source="source"
        :loading="loading"
        :pagination="pagination"
        :onChange="handlePage">
      <template v-slot:bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'time'">
          <p>{{ record.time }}</p>
          <p>{{ format(record.timeCost) }}</p>
        </template>

        <template v-else-if="column.dataIndex === 'state'">
          <a-tag v-if="record.state === 1" :bordered="false" color="green">正常</a-tag>
          <a-tag v-else :bordered="false" color="orange">已下发</a-tag>
        </template>

        <template v-else-if="column.dataIndex === 'act'">
          <a-button danger @click="delCk(record)" size="small">删除</a-button>
        </template>

        <template v-else>
          {{ record[column.dataIndex] }}
        </template>
      </template>
    </a-table>
    <template #footer></template>
  </a-modal>

  <add-club-pass-modal ref="add" @refresh="query"/>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'
import {message, Modal} from "ant-design-vue"

import AddClubPassModal from '@/views/user/AddClubPass.vue'

const remain = ref(0)

const add = ref(null)

const source = ref([])
const modal = ref(false)
const loading = ref(false)
const pagination = ref({
  showSizeChanger: true,
  defaultCurrent: 1,
  defaultPageSize: 10,
  current: 1,
  pageSize: 10,
  total: 0,
  search: '',
  onShowSizeChange: (current, size) => {
    pagination.value.current = 1
    pagination.value.pageSize = size
    query()
  }
})

const columns = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
  }, {
    key: 'code',
    title: '兑换码',
    dataIndex: 'code',
  }, {
    key: 'orderId',
    title: '订单ID',
    dataIndex: 'orderId',
  }, {
    key: 'state',
    title: '状态',
    dataIndex: 'state',
  }, {
    key: 'time',
    title: '创建时间',
    dataIndex: 'createTime',
  }, {
    key: 'time',
    title: '下发时间',
    dataIndex: 'updateTime',
  }, {
    key: 'act',
    title: '操作',
    dataIndex: 'act',
  }
]

function search() {
  pagination.value.current = 1
  query()
}

function query() {
  loading.value = true

  axios.get('/club', {
    params: {
      page: pagination.value.current,
      size: pagination.value.pageSize,
      search: pagination.value.search,
    }
  }).then((res) => {
    loading.value = false

    if (res.code === 1) {
      remain.value = res.data.remain
      pagination.value.current = res.data.page
      pagination.value.total = res.data.total
      source.value = res.data.data
    }
  })
}

function openCk() {
  query()
  modal.value = true
}

function cancelCk() {
  modal.value = false
}

function addCk() {
  add.value.openCk()
}

function assignCk() {
  loading.value = true
  axios.post('/club-assign').then((res) => {
    if (res.code === 1) {
      message.success('操作成功')
      query()
    }
    loading.value = false
  })
}

function reassignCk() {
  loading.value = true
  axios.post('/club-reassign').then((res) => {
    if (res.code === 1) {
      message.success('操作成功')
      query()
    }
    loading.value = false
  })
}

function handlePage(param) {
  pagination.value.current = param.current
  pagination.value.pageSize = param.pageSize
  query()
}

function delCk(record) {
  Modal.confirm({
    content: '确定删除吗？',
    okText: '删除',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      axios.post(`/club/${record.id}/state`, {
        state: 0
      }).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

defineExpose({openCk})
</script>
