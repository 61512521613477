<template>
  <a-modal title="物流信息"
           :open="modal"
           :width="800"
           cancel-text="关闭" @cancel="cancelCk">
    <a-table
        size="small"
        :columns="columns"
        :data-source="source"
        :loading="loading"
        :pagination="false"
    />

    <template #footer></template>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

const modal = ref(false)
const loading = ref(false)
const source = ref([])

const columns = [
  {
    key: 'time',
    title: '时间',
    dataIndex: 'time',
  }, {
    key: 'title',
    title: '事件',
    dataIndex: 'title',
  }, {
    key: 'desc',
    title: '描述',
    dataIndex: 'desc',
    width: 560,
  },
]

function query(id) {
  loading.value = true

  axios.get(`/order/${id}/postal`).then((res) => {
    loading.value = false

    if (res.code === 1) {
      source.value = res.data.progress
      modal.value = true
    }
  })
}

function openCk(record) {
  query(record.id)
  modal.value = true
}

function cancelCk() {
  modal.value = false
  loading.value = false
}

defineExpose({openCk})
</script>
