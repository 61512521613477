<template>
  <a-config-provider :locale="zhCN">
    <router-view v-slot="{ Component }">
      <a-layout :style="{height: '100vh'}">
        <a-layout-header v-if="store.state.auth && store.state.auth.token">
          <nav-bar/>
        </a-layout-header>
        <a-layout-content style="padding: 15px 30px">
          <transition name="fade" mode="out-in">
            <div :key="$route.path">
              <component :is="Component"/>
            </div>
          </transition>
        </a-layout-content>
      </a-layout>
    </router-view>
  </a-config-provider>
</template>

<script setup>
import {onMounted} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router/dist/vue-router'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import NavBar from '@/components/Nav.vue'

const store = useStore()
const router = useRouter()

dayjs.locale('zh-cn');

onMounted(() => {
  // TODO 升版本
  console.log('v1.0.2')

  const nowTime = Math.floor(new Date().getTime() / 1000)
  if (store.state.auth && store.state.auth.token && store.state.expire > nowTime) {
    return
  }

  const pkg = JSON.parse(localStorage.getItem('fkmAuth'))
  if (pkg && pkg.token && pkg.expire > nowTime) {
    store.commit('setAuth', pkg)
    return
  }

  localStorage.removeItem('fkmAuth')

  router.push({
    path: '/login'
  })
})
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.fade-enter-active {
  transition: all 0.7s ease;
}

.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.6, 0.6, 1);
}

.nav {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-search {
  width: 180px;
}

.nav-selector {
  width: 100px;
}

.nav-no-title {
  width: 100%;
  margin-bottom: 10px;
  justify-content: end;
}
</style>
