<template>
  <div class="nav">
    <a-typography-title :level="4">商品</a-typography-title>
    <a-space>
      <a-button @click="searchLogCk">搜索关键词</a-button>
      <a-button @click="clearCk" :loading="loading">清除缓存</a-button>
      <a-button @click="stockCk" :loading="loading">刷新库存</a-button>
      <a-button type="primary" @click="addItemCk">添加</a-button>
      <a-button @click="sortItemCk">排序</a-button>
      <a-input-search v-model:value="pagination.search"
                      class="nav-search"
                      placeholder="搜索"
                      enter-button
                      allow-clear
                      @search="search"/>
      <a-button @click="query" :loading="loading">刷新</a-button>
    </a-space>
  </div>

  <a-table
      size="small"
      :columns="columns"
      :data-source="source"
      :pagination="pagination"
      :loading="loading"
      :onChange="handlePage"
      :scroll="{ x: 2421 }"
  >
    <template v-slot:bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'thumbnail'">
        <a-image :src="record.thumbnail" :width="30" :height="30"/>
      </template>

      <template v-else-if="column.dataIndex === 'name'">
        {{ record.name }}
        <a-tag color="blue" v-if="record.album > 0">抽卡</a-tag>
      </template>

      <template v-else-if="column.dataIndex === 'price'">
        <template v-if="record.discount <= 0">
          {{ record.price }}
        </template>
        <template v-else>
          <del>{{ record.price }}</del>
          /
          <a-typography-text type="danger">{{ record.discount }}</a-typography-text>
        </template>
      </template>

      <template v-else-if="column.dataIndex === 'stock'">
        <a-popover>
          <template #content>
            <div class="branch-list">
              <a-list size="small" item-layout="vertical" bordered :data-source="record.stock">
                <template #renderItem="{ item }">
                  <a-list-item>
                    {{ item.branch }}
                    <template #extra>
                      <div class="align">
                        <div>待支付：</div>
                        <a-typography-link>{{ item.unstable }}</a-typography-link>
                      </div>
                      <div class="align">
                        <div>实时：</div>
                        <a-typography-link>{{ item.cache }}</a-typography-link>
                      </div>
                      <div class="align">
                        <div>数据库：</div>
                        <a-typography-link>{{ item.db }}</a-typography-link>
                      </div>
                    </template>
                  </a-list-item>
                </template>
              </a-list>
            </div>
          </template>

          <div class="align">
            <div>待支付：</div>
            <a-typography-link>{{ record.stockWait }}</a-typography-link>
          </div>
          <div class="align">
            <div>实时：</div>
            <a-typography-link>{{ record.stockCache }}</a-typography-link>
          </div>
          <div class="align">
            <div>数据库：</div>
            <a-typography-link>{{ record.stockDb }}</a-typography-link>
          </div>
        </a-popover>
      </template>

      <template v-else-if="column.dataIndex === 'view'">
        <div>浏览：{{ record.view }}</div>
        <div>分享：{{ record.share }}</div>
      </template>

      <template v-else-if="column.dataIndex === 'branch'">
        <a-button size="small" type="primary" @click="branchCk(record)">详情</a-button>
      </template>

      <template v-else-if="column.dataIndex === 'time'">
        <template v-if="record.showTime">
          <div>{{ record.startTime }} ~</div>
          <div>{{ record.endTime }}</div>
        </template>
        <a-tag v-else :bordered="false" color="red">不显示</a-tag>
      </template>

      <template v-else-if="column.dataIndex === 'thumb'">
        <a-button size="small" type="dashed" @click="thumbCk(record)">详情</a-button>
      </template>

      <template v-else-if="column.dataIndex === 'sale'">
        <div>真实：{{ record.sale }}</div>
        <div>虚假：{{ record.fakeSale }}</div>
      </template>

      <template v-else-if="column.dataIndex === 'showAttach'">
        <a-tag v-if="record.showAttach" color="green">显示</a-tag>
        <a-tag v-else color="red">不显示</a-tag>
      </template>

      <template v-else-if="column.dataIndex === 'attach'">
        <a-button size="small" type="dashed" @click="attachCk(record)">详情</a-button>
      </template>

      <template v-else-if="column.dataIndex === 'state'">
        <a-space>
          <template v-if="record.state === 1">
            <a-tag :bordered="false" color="orange">正常</a-tag>
            <a-button v-if="store.state.auth.group === 0" type="primary" size="small" @click="stateCk(record, 2)">列表上架
            </a-button>
            <a-button v-if="store.state.auth.group === 0" size="small" @click="stateCk(record, 3)">链接上架</a-button>
          </template>
          <template v-else-if="record.state === 2">
            <a-tag :bordered="false" color="green">列表上架</a-tag>
            <a-button v-if="store.state.auth.group === 0" size="small" danger @click="stateCk(record, 1)">下架</a-button>
          </template>
          <template v-else>
            <a-tag class="lnk" :bordered="false" color="blue" @click="qrCk(record)">链接上架</a-tag>

            <a-button v-if="store.state.auth.group === 0" size="small" danger @click="stateCk(record, 1)">下架</a-button>
          </template>
        </a-space>
      </template>

      <template v-else-if="column.dataIndex === 'act'">
        <a-space>
          <a-button @click="previewCk(record)" size="small">预览</a-button>
          <a-button type="dashed" @click="clearItemCk(record)" size="small">清除缓存</a-button>
          <a-button @click="updItemCk(record)" size="small">编辑</a-button>
          <a-button danger @click="delItemCk(record)" size="small">删除</a-button>
        </a-space>
      </template>

      <template v-else>
        {{ record[column.dataIndex] }}
      </template>
    </template>
  </a-table>

  <add-item-modal ref="addItem" @refresh="query"/>

  <upd-item-modal ref="updItem" @refresh="query"/>

  <branch-modal ref="branch" @refresh="query"/>

  <attach-modal ref="attach"/>

  <thumb-modal ref="thumb"/>

  <sort-modal ref="sortItem" @refresh="query"/>

  <search-log-modal ref="searchLog"/>

  <preview-modal ref="preview"/>

  <link-qr-modal ref="qr"/>
</template>

<script setup>
import {ref} from 'vue'
import {message, Modal} from 'ant-design-vue'
import {useStore} from 'vuex'
import axios from '@/axios'

import AddItemModal from '@/views/item/AddItem.vue'
import SortModal from '@/components/Sort.vue'
import UpdItemModal from '@/views/item/UpdItem.vue'
import BranchModal from '@/views/item/Branch.vue'
import AttachModal from '@/views/item/Attach.vue'
import ThumbModal from '@/views/item/Thumb.vue'
import SearchLogModal from '@/views/item/SearchLog.vue'
import PreviewModal from '@/views/item/Preview.vue'
import LinkQrModal from '@/views/item/LinkQr.vue'

const store = useStore()

const addItem = ref(null)
const updItem = ref(null)
const sortItem = ref(null)
const searchLog = ref(null)
const branch = ref(null)
const thumb = ref(null)
const attach = ref(null)
const preview = ref(null)
const qr = ref(null)

const source = ref([])
const pagination = ref({
  showSizeChanger: true,
  defaultCurrent: 1,
  defaultPageSize: 10,
  current: 1,
  pageSize: 10,
  total: 0,
  search: '',
  onShowSizeChange: (current, size) => {
    pagination.value.current = 1
    pagination.value.pageSize = size
    query()
  }
})
const loading = ref(false)

const columns = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
  }, {
    key: 'name',
    title: '商品名',
    dataIndex: 'name',
  }, {
    key: 'thumbnail',
    title: '缩略图',
    dataIndex: 'thumbnail',
  }, {
    key: 'price',
    title: '价格 [元]',
    dataIndex: 'price',
  }, {
    key: 'stock',
    title: '库存',
    dataIndex: 'stock',
  }, {
    key: 'sale',
    title: '销量',
    dataIndex: 'sale',
  }, {
    key: 'saleTotal',
    title: '销售额 [元]',
    dataIndex: 'saleTotal',
  }, {
    key: 'view',
    title: '曝光',
    dataIndex: 'view',
  }, {
    key: 'branch',
    title: '规格',
    dataIndex: 'branch',
  }, {
    key: 'thumb',
    title: '图片',
    dataIndex: 'thumb',
  }, {
    key: 'attach',
    title: '解锁项',
    dataIndex: 'attach',
  }, {
    key: 'time',
    title: '时间',
    dataIndex: 'time',
    width: 180,
  }, {
    key: 'state',
    title: '状态',
    dataIndex: 'state',
  }, {
    key: 'act',
    title: '操作',
    dataIndex: 'act',
  }
]

function search() {
  pagination.value.current = 1
  query()
}

function query() {
  loading.value = true

  axios.get('/item', {
    params: {
      page: pagination.value.current,
      size: pagination.value.pageSize,
      search: pagination.value.search,
    }
  }).then((res) => {
    loading.value = false

    if (res.code === 1) {
      pagination.value.current = res.data.page
      pagination.value.total = res.data.total
      source.value = res.data.data
    }
  })
}

function handlePage(param) {
  pagination.value.current = param.current
  pagination.value.pageSize = param.pageSize
  query()
}

function addItemCk() {
  addItem.value.openCk()
}

function sortItemCk() {
  sortItem.value.openCk('/item-sort')
}

function searchLogCk() {
  searchLog.value.openCk()
}

function branchCk(record) {
  branch.value.openCk(record)
}

function thumbCk(record) {
  thumb.value.openCk(record)
}

function attachCk(record) {
  attach.value.openCk(record)
}

function previewCk(record) {
  preview.value.openCk(record)
}

function qrCk(record) {
  qr.value.openCk(record)
}

function clearCk() {
  axios.delete('/item/0/cache').then((res) => {
    if (res.code === 1) {
      message.success('操作成功')
    }
  })
}

function stockCk() {
  axios.post('/item/stock').then((res) => {
    if (res.code === 1) {
      message.success('操作成功')
      query()
    }
  })
}

function clearItemCk(record) {
  axios.delete(`/item/${record.id}/cache`).then((res) => {
    if (res.code === 1) {
      message.success('操作成功')
    }
  })
}

function updItemCk(record) {
  updItem.value.openCk(record.id)
}

function stateCk(record, state) {
  Modal.confirm({
    content: '确定' + (state === 1 ? '下架' : '上架') + '吗？',
    okText: '继续',
    cancelText: '取消',
    onOk() {
      axios.post(`/item/${record.id}/state`, {
        state: state
      }).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

function delItemCk(record) {
  Modal.confirm({
    content: '确定删除吗？',
    okText: '删除',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      axios.post(`/item/${record.id}/state`, {
        state: 0
      }).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

query()
</script>

<style scoped>
.align {
  display: flex;
  justify-content: space-between;
}

.branch-list {
  max-height: 504px;
  overflow-y: scroll;
}

.lnk {
  cursor: pointer;
}
</style>