<template>
  <a-modal title="补发优惠劵"
           :open="modal"
           :confirm-loading="loading"
           :mask-closable="false"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :model="entity"
            :rules="rules">
      <a-form-item label="优惠劵" name="coupon">
        <a-select :options="couponList" v-model:value="entity.coupon"/>
      </a-form-item>
      <a-form-item label="有效期" name="expire">
        <a-input-number v-model:value="entity.expire" min="1"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'
import {message} from 'ant-design-vue'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const entity = ref({})
const couponList = ref([])

const rules = {
  expire: [{
    required: true,
    message: '有效期不能为空',
    trigger: 'blur'
  }],
}

let userId

function openCk(id) {
  userId = id

  axios.get(`/coupon-opt`).then((res) => {
    loading.value = false

    let tmp = []
    if (res.code === 1) {
      for (const i of res.data) {
        tmp.push({
          value: i.id,
          label: i.name,
        })
      }
      couponList.value = tmp
      if (tmp.length) {
        entity.value.coupon = tmp[0].value
        entity.value.expire = 30
      }

      modal.value = true
    }
  })
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    axios.post(`/coupon/${entity.value.coupon}/pub`, {
      userId: [userId],
      expire: entity.value.expire,
    }).then((res) => {
      loading.value = false

      if (res.code === 1) {
        message.success('操作成功')
        emits('refresh')
        modal.value = false
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  form.value.resetFields()
  loading.value = false
}

defineExpose({openCk})
</script>
