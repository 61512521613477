<template>
  <a-modal title="发放"
           :open="modal"
           :width="690"
           :mask-closable="false"
           :confirm-loading="loading"
           @ok="okCk"
           @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :model="entity"
            :rules="rules">
      <a-form-item label="有效期" name="expire">
        <a-input-number v-model:value="entity.expire" min="1"/>
      </a-form-item>

      <a-form-item label="玩家id" name="playerId" help="多个playerId用回车隔开，每行1个；若1个playerId出现多次，将发放多张优惠券">
        <a-textarea :rows="6" v-model:value="entity.playerId"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'
import {message} from 'ant-design-vue'

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const entity = ref({})
const rules = {
  expire: [{
    required: true,
    message: '有效期不能为空',
    trigger: 'blur'
  }],
  playerId: [{
    required: true,
    message: '玩家id不能为空',
    trigger: 'blur'
  }],
}

function openCk(record) {
  entity.value = {
    id: record.id,
    expire: record.expire,
    playerId: '',
  }
  modal.value = true
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    const playerId = ckPlayerId()

    axios.post(`/coupon/${entity.value.id}/anonymous-pub`, {
      playerId: playerId,
      expire: entity.value.expire,
    }).then((res) => {
      loading.value = false

      if (res.code === 1) {
        message.success('操作成功')
        modal.value = false
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  loading.value = false
}

function ckPlayerId() {
  let data = entity.value.playerId.split("\n")

  let res = []
  for (const i of data) {
    const line = i.replaceAll('"', '').replaceAll('\t', '').replaceAll(' ', '')
    if (line === '' || line.length < 14 || line.length > 18) {
      continue
    }
    res.push(line)
  }
  return res
}

defineExpose({openCk})
</script>
