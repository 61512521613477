<template>
  <a-modal title="修改地址"
           :open="modal"
           :confirm-loading="loading"
           :mask-closable="false"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :rules="rules"
            :model="entity"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }">
      <a-form-item label="收件人" name="name">
        <a-input v-model:value="entity.name"/>
      </a-form-item>

      <a-form-item label="号码" name="phone">
        <a-input v-model:value="entity.phone"/>
      </a-form-item>

      <a-form-item label="地区" name="area">
        <a-input v-model:value="entity.area"/>
      </a-form-item>

      <a-form-item label="地址" name="address">
        <a-textarea :rows="4" v-model:value="entity.address"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const entity = ref({})

const rules = {
  name: [{
    required: true,
    message: '收件人不能为空',
    trigger: 'blur'
  }],
  phone: [{
    required: true,
    message: '号码不能为空',
    trigger: 'blur'
  }],
  address: [{
    required: true,
    message: '地址不能为空',
    trigger: 'blur'
  }],
}

function openCk(record) {
  entity.value = {
    id: record.id,
    name: record.postName,
    area: record.postArea,
    phone: record.postPhone,
    address: record.postAddress,
  }
  modal.value = true
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    axios.post(`/order/${entity.value.id}/address`, entity.value).then((res) => {
      loading.value = false

      if (res.code === 1) {
        emits('refresh')
        modal.value = false
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  form.value.resetFields()
  loading.value = false
}

defineExpose({openCk})
</script>
