<template>
  <a-modal title="添加"
           :open="modal"
           :confirm-loading="loading"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :model="entity"
            :rules="rules"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }">
      <a-form-item label="名称" name="name">
        <a-input v-model:value="entity.name" :maxlength="32"/>
      </a-form-item>

      <a-form-item label="缩略图" name="thumbnail">
        <a-space>
          <a-input v-model:value="entity.thumbnail"/>
          <a-button type="primary" @click="thumbPickCk">选择</a-button>
        </a-space>
      </a-form-item>

      <a-form-item label="价格 [元]" name="price">
        <a-input-number min="0.01" v-model:value="entity.price"/>
      </a-form-item>

      <a-form-item label="优惠价 [元]" name="discount">
        <a-input-number min="0" v-model:value="entity.discount"/>
      </a-form-item>

      <a-form-item label="列表样式" name="listTheme">
        <a-select :options="listTheme" v-model:value="entity.listTheme"/>
      </a-form-item>

      <a-form-item label="详情样式" name="viewTheme">
        <a-select :options="viewTheme" v-model:value="entity.viewTheme"/>
      </a-form-item>

      <a-form-item label="显示时间">
        <a-switch v-model:checked="entity.showTime"/>
      </a-form-item>

      <template v-if="entity.showTime">
        <a-form-item label="开始时间" help="选择后，点击确定生效">
          <a-date-picker show-time placeholder="开始时间"
                         v-model:value="entity.startTime"/>
        </a-form-item>

        <a-form-item label="结束时间" help="选择后，点击确定生效">
          <a-date-picker show-time placeholder="结束时间"
                         v-model:value="entity.endTime"/>
        </a-form-item>
      </template>

      <a-form-item label="显示销量" name="showSale">
        <a-select :options="showSaleOpt" v-model:value="entity.showSale"/>
      </a-form-item>

      <a-form-item label="虚假销量" name="fakeSale">
        <a-input-number min="0" v-model:value="entity.fakeSale"/>
      </a-form-item>

      <a-form-item label="显示解锁项">
        <a-switch v-model:checked="entity.showAttach"/>
      </a-form-item>

      <a-form-item label="运费 [分]" name="transportFee">
        <a-select :options="transportFee" v-model:value="entity.transportFee"/>
      </a-form-item>

      <a-form-item label="发货时间" name="postDelay">
        <a-select :options="postDelay" v-model:value="entity.postDelay"/>
      </a-form-item>

      <a-form-item label="客服" name="serviceQr">
        <a-select :options="serviceQr" v-model:value="entity.serviceQr">
          <template #option="{ label }">
            <a-image :height="60" :src="label"/>
          </template>
        </a-select>
      </a-form-item>

      <a-form-item label="卡册" name="album">
        <a-select :options="albumList" v-model:value="entity.album"/>
      </a-form-item>
    </a-form>
  </a-modal>

  <thumb-pick-modal ref="thumbPick" @choose="handleChoose"/>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

import ThumbPickModal from '@/components/ThumbPick'

let listTheme = [],
    viewTheme = [],
    transportFee = [],
    postDelay = [],
    serviceQr = [],
    albumList = []

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const thumbPick = ref(null)
const entity = ref({
  name: '',
  thumbnail: '',
  price: 0,
  discount: 0,
  listTheme: 0,
  viewTheme: 0,
  showTime: false,
  startTime: '',
  endTime: '',
  showSale: 1,
  fakeSale: 0,
  showAttach: false,
  transportFee: 0,
  postDelay: 0,
  serviceQr: 0,
  album: 0,
})

const showSaleOpt = [
  {value: 1, label: '显示真实销量'},
  {value: 2, label: '显示虚假销量'},
  {value: 0, label: '不显示'}
]
const rules = {
  name: [{
    required: true,
    message: '名称不能为空',
    trigger: 'blur'
  }, {
    max: 32,
    message: '名称长度为1~32位',
    trigger: 'blur'
  }],
  thumbnail: [{
    required: true,
    message: '缩略图不能为空',
    trigger: 'blur'
  }],
  price: [{
    required: true,
    message: '价格不能为空',
    trigger: 'blur'
  }],
  discount: [{
    required: true,
    message: '优惠价不能为空',
    trigger: 'blur'
  }],
  fakeSale: [{
    required: true,
    message: '虚假销量不能为空',
    trigger: 'blur'
  }],
}

function openCk() {
  Promise.all([getConf(), getAlbum()]).then(() => {
    modal.value = true
  })
}

function getConf() {
  return new Promise((resolve, reject) => {
    axios.get('/item-conf').then((res) => {
      if (res.code !== 1) {
        reject()
        return
      }

      listTheme = res.data.listTheme
      viewTheme = res.data.viewTheme
      transportFee = res.data.transportFee
      postDelay = res.data.postDelay
      serviceQr = res.data.serviceQr

      if (listTheme.length) {
        entity.value.listTheme = listTheme[0].value
      }
      if (viewTheme.length) {
        entity.value.viewTheme = viewTheme[0].value
      }
      if (transportFee.length) {
        entity.value.transportFee = transportFee[0].value
      }
      if (postDelay.length) {
        entity.value.postDelay = postDelay[0].value
      }
      if (serviceQr.length) {
        entity.value.serviceQr = serviceQr[0].value
      }

      resolve()
    })
  })
}

function getAlbum() {
  return new Promise((resolve, reject) => {
    axios.get('/album-opt').then((res) => {
      if (res.code !== 1) {
        reject()
        return
      }

      albumList = res.data
      if (albumList.length) {
        albumList.unshift({
          value: 0,
          label: '非抽卡商品',
        })
      }
      resolve()
    })
  })
}

function thumbPickCk() {
  thumbPick.value.openCk()
}

function handleChoose(v) {
  entity.value.thumbnail = v
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    let data = {...entity.value}
    data.price = data.price * 100
    data.discount = data.discount * 100
    data.showTime = (data.showTime ? 1 : 0)
    data.showAttach = (data.showAttach ? 1 : 0)
    if (data.showTime) {
      data.startTime = data.startTime.format('YYYY-MM-DD HH:mm:ss')
      data.endTime = data.endTime.format('YYYY-MM-DD HH:mm:ss')
    } else {
      data.startTime = '2023-01-01 00:00:00'
      data.endTime = '2033-01-01 00:00:00'
    }

    axios.post('/item', data).then((res) => {
      loading.value = false

      if (res.code === 1) {
        emits('refresh')
        modal.value = false
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  form.value.resetFields()
  modal.value = false
  loading.value = false
}

defineExpose({openCk})
</script>
