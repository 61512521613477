<template>
  <a-modal title="添加"
           :open="modal"
           :confirmLoading="loading"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :rules="rules"
            :model="entity"
            :labelCol="{ span: 6 }"
            :wrapperCol="{ span: 18 }">
      <a-form-item label="玩家 ID" name="playerId">
        <a-input v-model:value="entity.playerId"/>
      </a-form-item>

      <a-form-item label="用户 ID" name="userId">
        <a-input v-model:value="entity.userId"/>
      </a-form-item>

      <a-form-item label="芒果TV UUID" name="uuid">
        <a-input v-model:value="entity.uuid"/>
      </a-form-item>

      <a-form-item label="用户名" name="name">
        <a-input v-model:value="entity.name"/>
      </a-form-item>

      <a-form-item label="头像" name="thumbnail">
        <a-input v-model:value="entity.thumbnail"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const entity = ref({
  userId: '',
  playerId: '',
  uuid: '',
  name: '',
  thumbnail: '',
})

const rules = {
  playerId: [{
    required: true,
    message: '玩家ID不能为空',
    trigger: 'blur'
  }],
}

function openCk() {
  modal.value = true
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    axios.post('/user', entity.value).then((res) => {
      loading.value = false

      if (res.code === 1) {
        emits('refresh')
        modal.value = false
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  form.value.resetFields()
  loading.value = false
}

defineExpose({openCk})
</script>
