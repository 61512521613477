<template>
  <a-modal title="添加"
           :open="modal"
           :confirloading="loading"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :rules="rules"
            :model="entity"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }">
      <a-form-item label="键" name="k">
        <a-input v-model:value="entity.k"/>
      </a-form-item>

      <a-form-item label="值" name="v">
        <a-textarea :rows="4" v-model:value="entity.v"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const entity = ref({
  k: '',
  v: '',
})

const rules = {
  k: [{
    required: true,
    message: '键不能为空',
    trigger: 'blur'
  }],
  v: [{
    required: true,
    message: '值不能为空',
    trigger: 'blur'
  }],
}

function openCk() {
  modal.value = true
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    axios.post('/config', entity.value).then((res) => {
      loading.value = false

      if (res.code === 1) {
        emits('refresh')
        modal.value = false
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  form.value.resetFields()
  loading.value = false
}

defineExpose({openCk})
</script>
