<template>
  <a-modal title="导出"
           :open="modal"
           :destroy-on-close="true"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form size="small"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }">
      <a-form-item label="开始时间">
        <a-date-picker v-model:value="exportOrder.startTime" format="YYYY-MM-DD" placeholder="开始时间"/>
      </a-form-item>

      <a-form-item label="结束时间">
        <a-date-picker v-model:value="exportOrder.endTime" format="YYYY-MM-DD" placeholder="结束时间"/>
      </a-form-item>

      <a-form-item label="类型" name="type">
        <a-select :options="typeList"
                  v-model:value="exportOrder.type"/>
      </a-form-item>

      <a-form-item label="商品" name="item">
        <a-select mode="multiple"
                  max-tag-count="responsive"
                  allowClear
                  :options="itemList"
                  placeholder="筛选商品"
                  v-model:value="exportOrder.item"/>
      </a-form-item>

      <a-form-item label="状态" name="state">
        <a-select :options="stateList" v-model:value="exportOrder.state"/>
      </a-form-item>

      <a-form-item label="打单">
        <a-checkbox v-model:checked="exportOrder.tpl"></a-checkbox>
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button type="primary" @click="okCk">导出</a-button>
    </template>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'
import {message} from "ant-design-vue"

const modal = ref(false)

const exportOrder = ref({})
const itemList = ref([])

const typeList = [
  {value: -1, label: '全部'},
  {value: 0, label: '普通'},
  {value: 1, label: '抽卡'},
  {value: 2, label: '发货'},
]
const stateList = [
  {value: -8, label: '全部'},
  {value: 0, label: '待支付'},
  {value: 1, label: '已支付'},
  {value: 5, label: '即将发货'},
  {value: 2, label: '已发货'},
  {value: 3, label: '交易完成'},
  {value: 4, label: '交易关闭'},
  {value: -2, label: '取消'},
  {value: -1, label: '过期'},
]

function okCk() {
  if (!exportOrder.value.startTime || !exportOrder.value.endTime) {
    return
  }

  let data = {...exportOrder.value}
  data.startTime = data.startTime.format('YYYY-MM-DD')
  data.endTime = data.endTime.format('YYYY-MM-DD')
  data.tpl = data.tpl ? 1 : 0

  axios.post('/order/export', data).then((res) => {
    if (res.code === 0) {
      return
    }

    modal.value = false
    message.info('正在导出，请稍候...')
    window.location.href = axios.defaults.baseURL + '/w1FtALWurHKQJcIZ?key=' + res.data
  })
}

function openCk() {
  axios.get('/order/export-item').then((res) => {
    if (res.code === 1) {
      itemList.value = res.data

      exportOrder.value = {
        startTime: '',
        endTime: '',
        type: -1,
        item: [],
        state: 1,
      }

      modal.value = true
    }
  })
}

function cancelCk() {
  modal.value = false
}

defineExpose({openCk})
</script>