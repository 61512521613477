<template>
  <div class="nav">
    <a-typography-title :level="4">用户</a-typography-title>
    <a-space>
      <a-button @click="clubCk">会员月卡</a-button>
      <a-button type="primary" @click="addCk">添加</a-button>
      <a-input-search v-model:value="pagination.search"
                      class="nav-search"
                      placeholder="搜索"
                      enter-button
                      allow-clear
                      @search="query"/>
    </a-space>
  </div>

  <a-table
      size="small"
      :columns="columns"
      :dataSource="source"
      :pagination="pagination"
      :loading="loading"
      :onChange="handlePage"
      :scroll="{ x: 2428 }"
  >
    <template v-slot:bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'thumbnail'">
        <a-image v-if="record.thumbnail" :src="record.thumbnail" :width="30" :height="30"/>
      </template>

      <template v-else-if="column.dataIndex === 'state'">
        <a-space>
          <template v-if="record.state === 1">
            <a-tag :bordered="false" color="green">正常</a-tag>
            <a-button size="small" danger @click="stateCk(record, 0)">禁用</a-button>
          </template>
          <template v-else>
            <a-tag :bordered="false" color="red">禁用</a-tag>
            <a-button size="small" type="primary" @click="stateCk(record, 1)">启用</a-button>
          </template>
        </a-space>
      </template>

      <template v-else-if="column.dataIndex === 'act'">
        <a-space>
          <a-button @click="backpackCk(record)" size="small">背包</a-button>
          <a-button @click="couponCk(record)" type="primary" size="small">优惠劵</a-button>
        </a-space>
      </template>

      <template v-else>
        {{ record[column.dataIndex] }}
      </template>
    </template>
  </a-table>

  <add-modal ref="add" @refresh="query"/>

  <coupon-modal ref="coupon"/>

  <backpack-modal ref="backpack"/>
  <club-modal ref="club"/>
</template>

<script setup>
import {ref} from 'vue'
import {message, Modal} from 'ant-design-vue'
import axios from '@/axios'

import AddModal from '@/views/user/Add.vue'
import CouponModal from '@/views/user/Coupon.vue'
import ClubModal from '@/views/user/ClubPass.vue'
import BackpackModal from '@/views/user/Backpack.vue'

const add = ref(null)
const coupon = ref(null)
const backpack = ref(null)
const club = ref(null)
const source = ref([])
const loading = ref(false)
const pagination = ref({
  showSizeChanger: true,
  defaultCurrent: 1,
  defaultPageSize: 10,
  current: 1,
  pageSize: 10,
  search: '',
  total: 0,
  onShowSizeChange: (current, size) => {
    pagination.value.current = 1
    pagination.value.pageSize = size
    query()
  }
})

const columns = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
  }, {
    key: 'userId',
    title: '用户ID',
    dataIndex: 'userId',
  }, {
    key: 'playerId',
    title: '玩家ID',
    dataIndex: 'playerId',
  }, {
    key: 'uuid',
    title: 'WXId',
    dataIndex: 'uuid',
  }, {
    key: 'name',
    title: '用户名',
    dataIndex: 'name',
  }, {
    key: 'thumbnail',
    title: '头像',
    dataIndex: 'thumbnail',
  }, {
    key: 'active',
    title: '活跃度',
    dataIndex: 'active',
  }, {
    key: 'state',
    title: '状态',
    dataIndex: 'state',
  }, {
    key: 'createTime',
    title: '注册时间',
    dataIndex: 'createTime',
  }, {
    key: 'act',
    title: '操作',
    dataIndex: 'act',
  }
]

function query() {
  loading.value = true

  axios.get('/user', {
    params: {
      page: pagination.value.current,
      size: pagination.value.pageSize,
      search: pagination.value.search,
    }
  }).then((res) => {
    loading.value = false

    if (res.code === 1) {
      pagination.value.current = res.data.page
      pagination.value.total = res.data.total
      source.value = res.data.data
    }
  })
}

function handlePage(param) {
  pagination.value.current = param.current
  pagination.value.pageSize = param.pageSize
  query()
}

function addCk() {
  add.value.openCk()
}

function stateCk(record, state) {
  Modal.confirm({
    content: '确定' + (state === 1 ? '启用' : '禁用') + '吗？',
    okText: '确定',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      axios.post(`/user/${record.id}/state`, {
        state: state
      }).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

function backpackCk(record) {
  backpack.value.openCk(record.id)
}

function couponCk(record) {
  coupon.value.openCk(record.id)
}

function clubCk() {
  club.value.openCk()
}

query()
</script>
