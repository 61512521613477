import App from '@/App.vue'
import {createApp} from 'vue'

// 顺序不能变
import axios from '@/axios'
import store from '@/store'
import router from '@/router'
import antd from 'ant-design-vue'
import {VueClipboard} from '@soerenmartius/vue3-clipboard'

const app = createApp(App)

app.config.globalProperties.$axios = axios
VueClipboard.config.autoSetContainer = true

app.use(router)
    .use(store)
    .use(antd)
    .use(VueClipboard)
    .mount('#app')