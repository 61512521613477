import axios from 'axios'
import store from '@/store'
import router from '@/router'
import {Modal} from 'ant-design-vue'

const service = axios.create({
    baseURL: 'https://api.wonderpop.fun/api',
    // baseURL: 'https://api.zhaomaomao.net/api',
    timeout: 30000,
    headers: {
        Authorization: ''
    },
})

// 请求
service.interceptors.request.use(function (config) {
    if (store.state.auth && store.state.auth.token) {
        config.baseURL = config.baseURL + '/tgUotVmQPF0PeFd3'
        config.headers.Authorization = 'bearer ' + store.state.auth.token
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

// 响应
service.interceptors.response.use(function (response) {
    if (response.data.code !== undefined) {
        switch (response.data.code) {
            case 0:
                Modal.error({
                    title: '错误',
                    content: response.data.msg,
                    okText: '我知道了'
                })
                break
            case 10:
                localStorage.removeItem('fkmAuth')
                store.commit('setAuth', null)

                router.push({
                    path: '/login'
                })
                break
            case 11:
                router.push({
                    path: '/item'
                })
                break
        }
    }
    return response.data
}, function (error) {
    return Promise.reject(error)
})

export default service
