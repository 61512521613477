<template>
  <a-modal title="批量发货"
           :open="modal"
           cancel-text="关闭" @cancel="cancelCk">
    <a-upload-dragger v-model:fileList="uploadList"
                      name="file"
                      action="https://api.wonderpop.fun/api/tgUotVmQPF0PeFd3/order/batch-delivery"
                      :headers="{Authorization: `bearer ${store.state.auth.token}`}"
                      @change="handleChange"
                      @drag="clear">
      <p class="ant-upload-text">点击 或 拖动表格到这里</p>
    </a-upload-dragger>
    <a-descriptions class="mt10" size="small" bordered :column="1" title="说明">
      <a-descriptions-item label="示例">
        <a-image src="https://gwimg.hitv.com/29/2401/0222/4804/BKvVxEIBZISCetM28WS/243868934006784000.png"/>
      </a-descriptions-item>
      <a-descriptions-item label="orderNo">内部订单号</a-descriptions-item>
      <a-descriptions-item label="限制">只有已支付的订单可以发货</a-descriptions-item>
    </a-descriptions>
    <template #footer>
      <a-button @click="cancelCk">关闭</a-button>
    </template>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import {useStore} from 'vuex'
import {message} from 'ant-design-vue'

const emits = defineEmits(['refresh'])

const store = useStore()

const modal = ref(false)
const loading = ref(false)
const uploadList = ref([])

function openCk() {
  modal.value = true
}

function handleChange(info) {
  const status = info.file.status
  if (status === 'done') {
    message.success('批量发货已完成')
    cancelCk()
    return
  }
  if (status === 'error') {
    message.error(`${info.file.name} 上传失败`)
  }
}

function clear() {
  uploadList.value = []
}

function cancelCk() {
  modal.value = false
  loading.value = false
  clear()
  emits('refresh')
}

defineExpose({openCk})
</script>

<style scoped>
.mt10 {
  margin-top: 10px;
}
</style>
