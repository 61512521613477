<template>
  <a-modal :title="`${itemName} 的解锁项`"
           :open="modal"
           :width="800"
           @cancel="cancelCk">
    <a-space class="nav-no-title">
      <a-button type="primary" @click="addCk" size="small">添加</a-button>
      <a-button @click="sortCk" size="small">排序</a-button>
    </a-space>

    <a-table
        size="small"
        :columns="columns"
        :data-source="source"
        :loading="loading"
        :pagination="false"
    >
      <template v-slot:bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'lockThumb'">
          <a-image :src="record.lockThumb" :width="30" :height="30"/>
        </template>

        <template v-else-if="column.dataIndex === 'unlockThumb'">
          <a-image :src="record.unlockThumb" :width="30" :height="30"/>
        </template>

        <template v-else-if="column.dataIndex === 'state'">
          <a-tag v-if="record.state === 1" :bordered="false">锁定</a-tag>
          <a-tag v-else :bordered="false" type="green">解锁</a-tag>
        </template>

        <template v-else-if="column.dataIndex === 'act'">
          <a-space>
            <a-button @click="updCk(record)" size="small">编辑</a-button>
            <a-button danger @click="delCk(record)" size="small">删除</a-button>
          </a-space>
        </template>

        <template v-else>
          {{ record[column.dataIndex] }}
        </template>
      </template>
    </a-table>

    <template #footer></template>
  </a-modal>

  <add-attach-modal ref="add" @refresh="query"/>

  <upd-attach-modal ref="upd" @refresh="query"/>

  <sort-modal ref="sort" @refresh="query"/>
</template>

<script setup>
import {ref} from 'vue'
import {message, Modal} from 'ant-design-vue'
import axios from '@/axios'

import AddAttachModal from '@/views/item/AddAttach.vue'
import UpdAttachModal from '@/views/item/UpdAttach.vue'
import SortModal from '@/components/Sort.vue'

const add = ref(null)
const upd = ref(null)
const sort = ref(null)
const source = ref([])
const modal = ref(false)
const loading = ref(false)

const columns = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
  }, {
    key: 'name',
    title: '名称',
    dataIndex: 'name',
  }, {
    key: 'lockThumb',
    title: '锁定图',
    dataIndex: 'lockThumb',
  }, {
    key: 'unlockThumb',
    title: '解锁图',
    dataIndex: 'unlockThumb',
  }, {
    key: 'state',
    title: '状态',
    dataIndex: 'state',
  }, {
    key: 'act',
    title: '操作',
    dataIndex: 'act',
  }
]

let itemId = 0,
    itemName = ''

function query() {
  loading.value = true

  axios.get(`/item/${itemId}/attach`).then((res) => {
    loading.value = false

    if (res.code === 1) {
      source.value = res.data
      modal.value = true
    }
  })
}

function addCk() {
  add.value.openCk(itemId)
}

function sortCk() {
  sort.value.openCk(`/item/${itemId}/attach/sort`)
}

function updCk(record) {
  upd.value.openCk(itemId, record)
}

function delCk(record) {
  Modal.confirm({
    content: '确定删除吗？',
    okText: '删除',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      axios.post(`/item/${itemId}/attach/${record.id}/state`, {
        state: 0
      }).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

function openCk(record) {
  itemId = record.id
  itemName = record.name
  query()
}

function cancelCk() {
  modal.value = false
}

defineExpose({openCk})
</script>
