<template>
  <div class="nav">
    <a-menu mode="horizontal"
            theme="dark"
            :selected-keys="current"
            :items="items"
            :onClick="menuCk"/>

    <a-space :size="20">
      <a-dropdown>
        <a-avatar size="large"
                  :style="{color: '#FFFFFF', backgroundColor: '#87D068', cursor: 'pointer',}">
          {{ store.state.auth.name }}
        </a-avatar>
        <template #overlay>
          <a-menu>
            <a-menu-item>
              <a href="javascript:;" @click="pwdCk">修改密码</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="logoutCk">退出登录</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </a-space>
  </div>
  <a-modal title="修改密码"
           :open="modal"
           :confirm-loading="loading"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="pwdCk">
    <a-form ref="form"
            size="small"
            :rules="rules"
            :model="entity"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }">
      <a-form-item label="原密码" name="oldPwd">
        <a-input-password v-model:value="entity.oldPwd"/>
      </a-form-item>
      <a-form-item label="新密码" name="newPwd">
        <a-input-password v-model:value="entity.newPwd"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {message} from 'ant-design-vue'
import axios from '@/axios'

const store = useStore()
const router = useRouter()

const current = ref([])
const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const entity = ref({
  oldPwd: '',
  newPwd: '',
})

const rules = {
  oldPwd: [{
    required: true,
    message: '原密码不能为空',
    trigger: 'blur'
  }],
  newPwd: [{
    required: true,
    message: '新密码不能为空',
    trigger: 'blur'
  }, {
    min: 7,
    max: 20,
    message: '新密码长度为7~20位',
    trigger: 'blur',
  },],
}
let items = []

function menuCk(evt) {
  const key = evt.key.toString()
  if (key !== current.value[0]) {
    current.value = [key]
    router.push({
      path: evt.item.path
    })
  }
}

function pwdCk() {
  modal.value = !modal.value
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    axios.post('/admin-pwd', entity.value).then((res) => {
      loading.value = false

      if (res.code === 1) {
        message.success('密码修改成功')
        modal.value = false
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function logoutCk() {
  if (loading.value) {
    return
  }

  loading.value = true
  axios.post('/logout').then(() => {
    loading.value = false

    localStorage.removeItem('fkmAuth')
    store.commit('setAuth', null)

    router.push({
      path: 'login'
    })
  })
}

function reselectNav() {
  for (const i of items) {
    if (window.location.href.indexOf(i.path) > -1) {
      current.value = [i.key]
      return
    }
  }
  if (store.state.auth.group > 0) {
    current.value = ['1']
    return
  }
  current.value = ['0']
}

function genMenu() {
  if (store.state.auth.group > 0) {
    items = [
      {
        key: '1',
        type: '',
        label: '商品',
        path: '/item',
      }, {
        key: '4',
        type: '',
        label: '素材',
        path: '/upload',
      }, {
        key: '8',
        type: '',
        label: '订单',
        path: '/order',
      },
    ]
    return
  }
  items = [
    {
      key: '0',
      type: '',
      label: '分类',
      path: '/category',
    }, {
      key: '1',
      type: '',
      label: '商品',
      path: '/item',
    }, {
      key: '2',
      type: '',
      label: '卡册',
      path: '/album',
    }, {
      key: '3',
      type: '',
      label: '卡',
      path: '/card',
    }, {
      key: '4',
      type: '',
      label: '素材',
      path: '/upload',
    }, {
      key: '5',
      type: '',
      label: '配置',
      path: '/config',
    }, {
      key: '6',
      type: '',
      label: '优惠劵',
      path: '/coupon',
    }, {
      key: '7',
      type: '',
      label: '用户',
      path: '/user',
    }, {
      key: '8',
      type: '',
      label: '订单',
      path: '/order',
    },
  ]
}

onMounted(() => {
  genMenu()
  reselectNav()
})
</script>
