<template>
  <a-modal title="选择素材"
           :open="modal"
           :width="800"
           :confirm-loading="loading"
           :mask-closable="false"
           cancel-text="关闭" @cancel="cancelCk">
    <div class="search-nav">
      <a-space>
        <a-select size="small"
                  class="nav-selector"
                  :options="category"
                  v-model:value="param.category"
                  @change="query"/>
        <a-input-search size="small"
                        class="nav-search"
                        placeholder="搜索名称"
                        v-model:value="param.search"
                        :loading="loading"
                        enter-button
                        @search="query"/>
      </a-space>
    </div>
    <a-space :wrap="true" align="start">
      <div v-for="(v, i) in source" :key="i" @click="choose(v.url)" class="selector">
        <a-image :width="35" :src="v.url"/>
        <a-typography-paragraph class="txt-center" :ellipsis="{ tooltip: v.name }" :content="v.name"/>
      </div>
    </a-space>
    <template #footer></template>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

const emits = defineEmits(['choose'])

const modal = ref(false)
const loading = ref(false)
const source = ref([])
const category = ref([])
const param = ref({
  search: '',
  category: 0,
})

let field

function query() {
  if (loading.value) {
    return
  }

  loading.value = true
  axios.get('/upload-ok', {
    params: param.value
  }).then((res) => {
    loading.value = false

    if (res.code === 1) {
      source.value = res.data
    }
  })
}

function categoryOption() {
  axios.get('/upload-category').then((res) => {
    if (res.code === 1) {
      let tmp = [{
        value: 0,
        label: '全部',
      }]
      for (const i of res.data) {
        tmp.push({
          value: i.value,
          label: i.label,
        })
      }
      category.value = tmp
      if (tmp.length) {
        param.value.category = tmp[0].value
      }
    }
  })
}

async function openCk(key) {
  categoryOption()
  field = key || ''
  await query('')
  modal.value = true
}

function cancelCk() {
  modal.value = false
}

function choose(val) {
  emits('choose', val, field)
  cancelCk()
}

defineExpose({openCk})
</script>

<style scoped>
.search-nav {
  margin-bottom: 20px;
  text-align: right;
}

.selector {
  width: 80px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px 10px 10px;
}

.txt-center {
  text-align: center;
}
</style>