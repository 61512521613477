<template>
  <div class="container">
    <a-typography-title :level="2" class="title">Wonder PoP</a-typography-title>
    <a-form ref="form"
            :rules="rules"
            :model="entity"
            layout="vertical"
            @keyup.enter="okCk">
      <a-form-item label="头衔" name="account">
        <a-input v-model:value="entity.account"/>
      </a-form-item>

      <a-form-item label="暗号" name="password">
        <a-input-password v-model:value="entity.password"/>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" block :loading="loading" @click="okCk">登记</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import axios from '@/axios'

const router = useRouter()
const store = useStore()

const loading = ref(false)
const form = ref(null)
const entity = ref({
  account: '',
  password: '',
})

const rules = {
  account: [{
    required: true,
    message: '账号不能为空',
    trigger: 'blur'
  }],
  password: [{
    required: true,
    message: '密码不能为空',
    trigger: 'blur'
  }, {
    min: 7,
    message: '密码最短7位',
    trigger: 'blur'
  }],
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    axios.post('/modJ6I7G0BbHNVSL', entity.value).then((res) => {
      loading.value = false

      if (res.code === 1) {
        setLocalStoreNx(res.data)

        router.push({
          path: res.data.group > 0 ? '/item' : '/category'
        })
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function setLocalStoreNx(data) {
  const nowTime = Math.floor(new Date().getTime() / 1000)
  const pkg = {
    name: data.username,
    token: data.token,
    expire: nowTime + data.expire,
    group: data.group,
  }

  localStorage.setItem('fkmAuth', JSON.stringify(pkg))
  store.commit('setAuth', pkg)
}

onMounted(() => {
  const pkg = JSON.parse(localStorage.getItem('fkmAuth'))
  if (!pkg || !pkg.token || isNaN(pkg.expire)) {
    return
  }

  const nowTime = Math.floor(new Date().getTime() / 1000)
  if (pkg.expire < nowTime) {
    return
  }

  store.commit('setAuth', pkg)

  router.push({
    path: pkg.group > 0 ? '/item' : '/category',
  })
})
</script>

<style scoped>
.container {
  max-width: 300px;
  margin: 24px auto 0 auto;
}

.title {
  text-align: center;
  margin-bottom: 24px;
}
</style>
