<template>
  <a-modal title="编辑"
           :open="modal"
           :confirm-loading="loading"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :rules="rules"
            :model="entity"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }">
      <a-form-item label="分类" name="name">
        <a-input v-model:value="entity.name" :maxlength="10"/>
      </a-form-item>

      <a-form-item label="缩略图" name="thumbnail">
        <a-space>
          <a-input v-model:value="entity.thumbnail"/>
          <a-button type="primary" @click="thumbPickCk">选择</a-button>
        </a-space>
      </a-form-item>
    </a-form>
  </a-modal>

  <thumb-pick-modal ref="thumbPick" @choose="handleChoose"/>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

import ThumbPickModal from '@/components/ThumbPick'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const thumbPick = ref(null)
const entity = ref({})

const rules = {
  name: [{
    required: true,
    message: '分类不能为空',
    trigger: 'blur'
  }, {
    max: 10,
    message: '名称长度为1~10位',
    trigger: 'blur'
  }],
}

function openCk(record) {
  entity.value = {
    id: record.id,
    pid: record.pid,
    name: record.name,
    thumbnail: record.thumbnail,
  }
  modal.value = true
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    axios.put(`/category/${entity.value.id}`, entity.value).then((res) => {
      loading.value = false

      if (res.code === 1) {
        emits('refresh')
        modal.value = false
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  form.value.resetFields()
  loading.value = false
}

function thumbPickCk() {
  thumbPick.value.openCk()
}

function handleChoose(v) {
  entity.value.thumbnail = v
}

defineExpose({openCk})
</script>
