<template>
  <div class="nav">
    <a-typography-title :level="4">素材</a-typography-title>
    <a-space>
      <a-button type="primary" @click="addCk">上传</a-button>
      <a-select :style="{width: '100px'}"
                :options="category"
                v-model:value="pagination.category"
                @change="query"/>
      <a-input-search v-model:value="pagination.search"
                      placeholder="搜索"
                      enter-button
                      allow-clear
                      @search="query"/>
    </a-space>
  </div>

  <a-table size="small"
           :columns="columns"
           row-key="id"
           :data-source="source"
           :pagination="pagination"
           :loading="loading"
           :onChange="handlePage"
           :scroll="{ x: 1100 }"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'name'">
        <a-space>
          <a-input size="small" v-model:value="nameMap[record.id].name"/>
          <a-button type="link" @click="saveCk(record.id)">保存</a-button>
        </a-space>
      </template>

      <template v-else-if="column.dataIndex === 'category'">
        <a-space>
          <a-select size="small" :options="category" v-model:value="nameMap[record.id].cate"/>
          <a-button type="link" @click="saveCk(record.id)">保存</a-button>
        </a-space>
      </template>

      <template v-else-if="column.dataIndex === 'url'">
        <a-button type="link" v-if="record.thumbnail" v-clipboard:copy="record.thumbnail"
                  v-clipboard:success="onCopyOk"
                  v-clipboard:error="onCopyErr">复制
        </a-button>
      </template>

      <template v-else-if="column.dataIndex === 'thumbnail'">
        <a-image v-if="record.thumbnail" :src="record.thumbnail" :height="50"/>
      </template>

      <template v-else-if="column.dataIndex === 'state'">
        <a-tag v-if="record.state === 1" :bordered="false" color="orange">待发布</a-tag>
        <a-tag v-else :bordered="false" color="green">正常</a-tag>
      </template>

      <template v-else-if="column.dataIndex === 'act'">
        <a-space>
          <a-button @click="pubCk(record)" size="small" v-if="record.state === 1">发布</a-button>
          <a-button danger @click="delCk(record)" size="small">删除</a-button>
        </a-space>
      </template>

      <template v-else>
        {{ record[column.dataIndex] }}
      </template>
    </template>
  </a-table>

  <add-modal ref="add" @refresh="query"/>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import {Modal, message} from 'ant-design-vue'
import axios from '@/axios'

import AddModal from '@/views/upload/Add.vue'

const add = ref(null)
const source = ref([])
const category = ref([])
const nameMap = ref({})
const loading = ref(false)
const pagination = ref({
  showSizeChanger: true,
  defaultCurrent: 1,
  defaultPageSize: 10,
  current: 1,
  pageSize: 10,
  category: 0,
  search: '',
  total: 0,
  onShowSizeChange: (current, size) => {
    pagination.value.current = 1
    pagination.value.pageSize = size
    query()
  }
})

const columns = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
  }, {
    key: 'thumbnail',
    title: '预览',
    dataIndex: 'thumbnail',
  }, {
    key: 'category',
    title: '分类',
    dataIndex: 'category',
  }, {
    key: 'name',
    title: '文件名',
    dataIndex: 'name',
  }, {
    key: 'url',
    title: '地址',
    dataIndex: 'url',
  }, {
    key: 'fileId',
    title: '文件ID',
    dataIndex: 'fileId',
  }, {
    key: 'state',
    title: '状态',
    dataIndex: 'state',
  }, {
    key: 'act',
    title: '操作',
    dataIndex: 'act',
  }
]

function query() {
  loading.value = true

  axios.get('/upload', {
    params: {
      page: pagination.value.current,
      size: pagination.value.pageSize,
      search: pagination.value.search,
      category: pagination.value.category,
    }
  }).then((res) => {
    loading.value = false

    if (res.code === 1) {
      pagination.value.current = res.data.page
      pagination.value.total = res.data.total
      source.value = res.data.data
      nameMap.value = {}

      for (const i of source.value) {
        nameMap.value[i.id] = {
          name: i.name,
          cate: i.category,
        }
      }
    }
  })
}

function handlePage(param) {
  pagination.value.current = param.current
  pagination.value.pageSize = param.pageSize
  query()
}

function addCk() {
  add.value.openCk()
}

function pubCk(record) {
  if (loading.value) {
    return
  }

  loading.value = true
  axios.post(`/upload/${record.id}/pub`).then((res) => {
    loading.value = false
    if (res.code === 1) {
      message.success('操作成功')
      query()
    }
  })
}

function saveCk(id) {
  if (loading.value) {
    return
  }

  loading.value = true
  axios.put(`/upload/${id}`, {
    name: nameMap.value[id].name,
    category: nameMap.value[id].cate,
  }).then((res) => {
    loading.value = false
    if (res.code === 1) {
      message.success('操作成功')
    }
  })
}

function delCk(record) {
  Modal.confirm({
    content: '确定删除吗？',
    okText: '删除',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      axios.post(`/upload/${record.id}/state`, {
        state: 0
      }).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

function onCopyOk() {
  message.success('复制成功')
}

function onCopyErr() {
  message.error('复制失败')
}

function categoryList() {
  axios.get('/upload-category').then((res) => {
    if (res.code !== 1) {
      return
    }
    let tmp = [{
      value: 0,
      label: '全部',
    }]
    for (const i of res.data) {
      tmp.push({
        value: i.value,
        label: i.label,
      })
    }
    category.value = tmp
  })
}

onMounted(() => {
  categoryList()
})
query()
</script>
