<template>
  <a-modal :title="`${branchName} 的卡包`"
           :open="modal"
           :width="1200"
           @cancel="cancelCk">
    <a-space class="nav-no-title">
      <a-button @click="addCk" size="small">添加</a-button>
      <a-button type="primary" @click="batchAddCk" size="small">批量添加</a-button>
      <a-button danger @click="batchDelCk" size="small">批量删除</a-button>
      <a-input-search v-model:value="pagination.search" size="small"
                      class="nav-search"
                      placeholder="搜索"
                      enter-button
                      allow-clear
                      @search="search"/>
    </a-space>
    <a-table
        size="small"
        :columns="columns"
        :data-source="source"
        :pagination="pagination"
        :loading="loading"
        :onChange="handlePage"
    >
      <template v-slot:bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'card'">
          <a-space>
            <template v-for="(v, i) in record.card" :key="i">
              <a-popover>
                <template #content>
                  <a-image :src="v.thumbnail" :height="160"/>
                </template>
                <a-typography-link>{{ v.name }}</a-typography-link>
                × {{ v.count }}
              </a-popover>
            </template>
          </a-space>
        </template>

        <template v-else-if="column.dataIndex === 'state'">
          <a-tag v-if="record.state === 1" :bordered="false">未拆</a-tag>
          <a-tag v-else :bordered="false" color="orange">已拆</a-tag>
        </template>

        <template v-else-if="column.dataIndex === 'act'">
          <a-space>
            <a-button danger size="small" @click="delCk(record)">删除</a-button>
          </a-space>
        </template>

        <template v-else>
          {{ record[column.dataIndex] }}
        </template>
      </template>
    </a-table>

    <template #footer></template>
  </a-modal>

  <add-branch-modal ref="add" @refresh="query"/>

  <upd-branch-modal ref="upd" @refresh="query"/>

  <add-pack-modal ref="addPack" @refresh="query"/>

  <batch-add-modal ref="batchPack" @refresh="query"/>

  <batch-del-modal ref="batchUnpack" @refresh="query"/>
</template>

<script setup>
import {ref} from 'vue'
import {message, Modal} from 'ant-design-vue'
import axios from '@/axios'

import AddBranchModal from '@/views/item/AddBranch.vue'
import UpdBranchModal from '@/views/item/UpdBranch.vue'
import AddPackModal from '@/views/item/AddPack.vue'
import BatchAddModal from '@/views/item/BatchPack.vue'
import BatchDelModal from '@/views/item/BatchUnpack.vue'

const emits = defineEmits(['refresh'])

const addPack = ref(null)
const batchPack = ref(null)
const batchUnpack = ref(null)
const source = ref([])
const pagination = ref({
  showSizeChanger: true,
  defaultCurrent: 1,
  defaultPageSize: 10,
  current: 1,
  pageSize: 10,
  total: 0,
  search: -2,
  onShowSizeChange: (current, size) => {
    pagination.value.current = 1
    pagination.value.pageSize = size
    query()
  }
})
const modal = ref(false)
const loading = ref(false)

const columns = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
  }, {
    key: 'userId',
    title: '用户ID',
    dataIndex: 'userId',
  }, {
    key: 'orderId',
    title: '订单ID',
    dataIndex: 'orderId',
  }, {
    key: 'count',
    title: '数量',
    dataIndex: 'count',
  }, {
    key: 'card',
    title: '卡牌',
    dataIndex: 'card',
  }, {
    key: 'state',
    title: '状态',
    dataIndex: 'state',
  }, {
    key: 'time',
    title: '添加时间',
    dataIndex: 'time',
  }, {
    key: 'act',
    title: '操作',
    dataIndex: 'act',
  }
]

let branchId = 0,
    branchName = ''

function query() {
  loading.value = true

  axios.get(`/branch/${branchId}/pack`, {
    params: {
      page: pagination.value.current,
      size: pagination.value.pageSize,
      search: pagination.value.search,
    }
  }).then((res) => {
    loading.value = false
    if (res.code === 1) {
      pagination.value.current = res.data.page
      pagination.value.total = res.data.total
      source.value = res.data.data
    }
  })
}

function search() {
  pagination.value.current = 1
  if (pagination.value.search === '') {
    pagination.value.search = -2
  }
  query()
}

function batchAddCk() {
  batchPack.value.openCk(branchId)
}

function batchDelCk() {
  batchUnpack.value.openCk(branchId)
}

function addCk() {
  addPack.value.openCk(branchId)
}

function delCk(record) {
  Modal.confirm({
    content: '确定删除吗？',
    okText: '删除',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      axios.delete(`/branch/${branchId}/pack/${record.id}`).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

function openCk(record) {
  branchId = record.id
  branchName = record.name
  pagination.value.current = 1
  query()
  modal.value = true
}

function cancelCk() {
  emits('refresh')
  modal.value = false
}

function handlePage(param) {
  pagination.value.current = param.current
  pagination.value.pageSize = param.pageSize
  query()
}

defineExpose({openCk})
</script>
