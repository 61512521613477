<template>
  <a-modal title="修改价格"
           :open="modal"
           :confirm-loading="loading"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :rules="rules"
            :model="entity">
      <a-form-item name="price" help="修改后，用户支付金额 = 商品原价 - 新的抵扣金额 + 运费">
        <a-input-number min="0" :style="{ width: '100%' }" v-model:value="entity.price"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const entity = ref({})

const rules = {
  price: [{
    required: true,
    message: '支付金额不能为空',
    trigger: 'blur'
  }],
}

function openCk(record) {
  entity.value = {
    id: record.id,
    price: record.coupon,
  }
  modal.value = true
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    let data = {...entity.value}
    data.price = data.price * 100

    axios.post(`/order/${entity.value.id}/price`, data).then((res) => {
      loading.value = false

      if (res.code === 1) {
        emits('refresh')
        modal.value = false
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  form.value.resetFields()
  loading.value = false
}

defineExpose({openCk})
</script>
