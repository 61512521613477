<template>
  <a-modal title="添加"
           :open="modal"
           :confirm-loading="loading"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :rules="rules"
            :model="entity"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }">
      <a-form-item label="名称" name="name">
        <a-input v-model:value="entity.name"/>
      </a-form-item>

      <a-form-item label="正面" name="thumbnail">
        <a-space>
          <a-input v-model:value="entity.thumbnail"/>
          <a-button type="primary" @click="thumbPickCk('thumbnail')">选择</a-button>
        </a-space>
      </a-form-item>

      <a-form-item label="背面" name="backThumbnail">
        <a-space>
          <a-input v-model:value="entity.backThumbnail"/>
          <a-button type="primary" @click="thumbPickCk('backThumbnail')">选择</a-button>
        </a-space>
      </a-form-item>

      <a-form-item label="稀有度" name="rare">
        <a-input-number min="0.01" v-model:value="entity.rare"/>
      </a-form-item>

      <a-form-item label="库存" name="price">
        <a-input-number min="0" v-model:value="entity.stock"/>
      </a-form-item>
    </a-form>
  </a-modal>

  <thumb-pick-modal ref="thumbPick" @choose="handleChoose"/>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

import ThumbPickModal from '@/components/ThumbPick'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const thumbPick = ref(null)
const entity = ref({
  name: '',
  thumbnail: '',
  backThumbnail: '',
  rare: 0,
  stock: 0,
})

const rules = {
  name: [{
    required: true,
    message: '名称不能为空',
    trigger: 'blur'
  }],
  thumbnail: [{
    required: true,
    message: '正面不能为空',
    trigger: 'blur'
  }],
  backThumbnail: [{
    required: true,
    message: '背面不能为空',
    trigger: 'blur'
  }],
  stock: [{
    required: true,
    message: '库存不能为空',
    trigger: 'blur'
  }]
}

let album = 0

function openCk(albumId) {
  album = albumId
  modal.value = true
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    let data = {...entity.value}
    data.album = album

    axios.post('/card', data).then((res) => {
      loading.value = false

      if (res.code === 1) {
        emits('refresh')
        modal.value = false
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  form.value.resetFields()
  loading.value = false
}

function thumbPickCk(key) {
  thumbPick.value.openCk(key)
}

function handleChoose(v, f) {
  if (f === 'thumbnail') {
    entity.value.thumbnail = v
  } else if (f === 'backThumbnail') {
    entity.value.backThumbnail = v
  }
}

defineExpose({openCk})
</script>
