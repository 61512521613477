<template>
  <a-modal title="背包"
           :open="modal"
           :width="1200"
           @cancel="cancelCk">
    <a-space class="nav-no-title">
      <template v-if="pagination.group">
        收藏进度
        <a-tag :bordered="false" :color="pagination.total === pagination.albumTotal ? 'green' : 'blue'">
          {{ pagination.total }} / {{ pagination.albumTotal }}
        </a-tag>
      </template>
      <template v-else>
        数量
        <a-tag :bordered="false" color="red">{{ pagination.total }}</a-tag>
        已发货
        <a-tag :bordered="false" color="green">{{ pagination.post }}</a-tag>
      </template>
      <a-checkbox v-model:checked="pagination.group"
                  @change="search">聚合
      </a-checkbox>
      <a-select :options="albumList"
                v-model:value="albumId"
                size="small"
                @change="search"/>
      <a-input-search class="nav-search"
                      size="small"
                      v-model:value="pagination.search"
                      placeholder="搜索"
                      enter-button
                      allow-clear
                      @search="search"/>
    </a-space>

    <a-table
        size="small"
        :columns="columns"
        :dataSource="source"
        :pagination="pagination"
        :loading="loading"
        :onChange="handlePage"
    >
      <template v-slot:bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'thumbnail'">
          <a-image v-if="record.thumbnail" :src="record.thumbnail" :height="30"/>
        </template>

        <template v-else-if="column.dataIndex === 'pState'">
          <a-space>
            <a-tag v-if="record.pState === 0" :bordered="false" color="orange">未拆</a-tag>
            <a-tag v-else :bordered="false" color="green">已拆</a-tag>
          </a-space>
        </template>

        <template v-else-if="column.dataIndex === 'state'">
          <a-space>
            <a-tag v-if="record.state === 0" :bordered="false" color="orange">未发</a-tag>
            <a-tag v-else-if="record.state === 1" :bordered="false" color="red">锁定</a-tag>
            <a-tag v-else :bordered="false" color="green">已发</a-tag>
          </a-space>
        </template>

        <template v-else>
          {{ record[column.dataIndex] }}
        </template>
      </template>
    </a-table>

    <template #footer></template>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

const albumId = ref(0)
const albumList = ref([])
const source = ref([])

const modal = ref(false)
const loading = ref(false)

const pagination = ref({
  showSizeChanger: true,
  defaultCurrent: 1,
  defaultPageSize: 10,
  current: 1,
  pageSize: 10,
  search: '',
  total: 0,
  group: false,
  post: 0,
  albumTotal: 0,
  onShowSizeChange: (current, size) => {
    pagination.value.current = 1
    pagination.value.pageSize = size
    query()
  }
})

const columns = [
  {
    key: 'pid',
    title: '卡包ID',
    dataIndex: 'pid',
  }, {
    key: 'pState',
    title: '卡包状态',
    dataIndex: 'pState',
  }, {
    key: 'orderId',
    title: '订单ID',
    dataIndex: 'orderId',
  }, {
    key: 'cardId',
    title: '卡ID',
    dataIndex: 'cardId',
  }, {
    key: 'name',
    title: '名称',
    dataIndex: 'name',
  }, {
    key: 'count',
    title: '数量',
    dataIndex: 'count',
  }, {
    key: 'thumbnail',
    title: '正面',
    dataIndex: 'thumbnail',
  }, {
    key: 'state',
    title: '状态',
    dataIndex: 'state',
  }, {
    key: 'time',
    title: '时间',
    dataIndex: 'time',
  },
]

let userId = 0

function query() {
  loading.value = true

  axios.get(`/user/${userId}/album/${albumId.value}`, {
    params: {
      page: pagination.value.current,
      size: pagination.value.pageSize,
      search: pagination.value.search,
      group: pagination.value.group ? 1 : 0,
    }
  }).then((res) => {
    loading.value = false

    if (res.code === 1) {
      pagination.value.current = res.data.page
      pagination.value.total = res.data.total
      pagination.value.post = res.data.post
      pagination.value.albumTotal = res.data.albumTotal
      source.value = res.data.data
    }
  })
}

function handlePage(param) {
  pagination.value.current = param.current
  pagination.value.pageSize = param.pageSize
  query()
}

function album() {
  axios.get('/album-opt').then((res) => {
    if (res.code === 1) {
      albumList.value = res.data
      if (albumList.value.length) {
        albumId.value = albumList.value[0].value
      }
      query()
    }
  })
}

function search() {
  pagination.value.current = 1
  query()
}

function cancelCk() {
  modal.value = false
}

function openCk(record) {
  userId = record
  pagination.value.current = 1
  modal.value = true
  album()
}

defineExpose({openCk})
</script>
