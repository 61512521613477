import {createStore} from 'vuex'

export default createStore({
    state: {
        auth: null,
    },
    mutations: {
        setAuth(state, param) {
            state.auth = param
        }
    },
})