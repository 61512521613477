<template>
  <a-modal title="预览"
           :open="modal"
           @cancel="cancelCk">
    <div class="nav-no-title">
      <div>预览样式仅供参考，以移动端表现为准</div>
      <div>分辨率
        <a-select size="small" :options="list" v-model:value="current" @change="handleSel"/>
      </div>
    </div>
    <div class="wrap">
      <iframe :src="rect.url" :width="rect.width" :height="rect.height" class="frame"/>
    </div>
    <template #footer></template>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'

const current = ref('375,667')
const rect = ref({
  url: '',
  width: 375,
  height: 667,
})
const modal = ref(false)

const host = 'https://fkmall.mgtv.com'
const list = [
  {value: '320,480', label: '320×480'},
  {value: '375,667', label: '375×667'},
  {value: '414,736', label: '414×736'},
  {value: '480,800', label: '480×800'},
]

let itemId = 0,
    isDraw = 0

function openCk(record) {
  itemId = record.id
  isDraw = record.album
  current.value = '375,667'
  rect.value.url = (isDraw > 0 ? `${host}/draw/${itemId}` : `${host}/preview/${itemId}`)
  modal.value = true
}

function cancelCk() {
  modal.value = false
}

function handleSel(v) {
  const part = v.split(',')
  rect.value.width = parseInt(part[0])
  rect.value.height = parseInt(part[1])
  rect.value.url = (isDraw > 0 ? `${host}/draw/${itemId}` : `${host}/item/${itemId}`)
}

defineExpose({openCk})
</script>

<style scoped>
.nav-no-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.wrap {
  text-align: center;
}

.frame {
  border: 1px solid #8C8C8C;
}
</style>