<template>
  <a-modal title="退款"
           :open="modal"
           :confirm-loading="loading"
           :mask-closable="false"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :model="entity"
            :rules="rules">
      <a-form-item label="退款金额" name="price" help="修改数量时不会自动计算更新金额，请根据数量人工核算金额">
        <a-input-number v-model:value="entity.price" min="0.01" :max="entity.priceFmt"/>
      </a-form-item>
      <a-form-item label="退款数量" name="count">
        <a-input-number v-model:value="entity.count" min="1" :max="entity.max"/>
      </a-form-item>
      <a-form-item label="退款原因">
        {{ entity.reason }}
      </a-form-item>
      <a-form-item label="是否通过" name="state">
        <a-select :options="stateList" v-model:value="entity.state"/>
      </a-form-item>
      <a-form-item label="商家反馈" name="notes">
        <a-textarea :rows="8" show-count :maxLength="1000" v-model:value="entity.notes"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'
import {Modal} from 'ant-design-vue'

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const entity = ref({})

const stateList = [{
  value: 1,
  label: '退款',
}, {
  value: 2,
  label: '驳回',
}]
const rules = {
  price: [{
    required: true,
    message: '退款金额不能为空',
    trigger: 'blur'
  }],
  count: [{
    required: true,
    message: '退款数量不能为空',
    trigger: 'blur'
  }],
  state: [{
    required: true,
  }],
  notes: [{
    required: true,
    message: '商家反馈不能为空',
    trigger: 'blur'
  }]
}

function openCk(record) {
  axios.get(`/order/${record.id}/refund`).then((res) => {
    loading.value = false

    if (res.code === 1) {
      entity.value = {
        id: record.id,
        price: res.data.priceFmt,
        count: res.data.count,
        max: res.data.max,
        reason: res.data.notes,
        state: 1,
        notes: '',
      }
      modal.value = true
    }
  })
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    axios.post(`/order/${entity.value.id}/refund`, {
      price: entity.value.price * 100,
      count: entity.value.count,
      state: entity.value.state,
      notes: entity.value.notes,
    }).then((res) => {
      loading.value = false

      if (res.code === 1) {
        Modal.success({
          title: '退款成功',
          content: '请求已经提交到支付系统，这个过程可能需要几分钟，请稍后刷新页面查看结果',
          okText: '我知道了',
          onOk: () => {
            modal.value = false
          }
        })
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  form.value.resetFields()
  loading.value = false
}

defineExpose({openCk})
</script>
