<template>
  <a-modal title="编辑"
           :open="modal"
           :confirm-loading="loading"
           ok-text="保存" @ok="okCk"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form ref="form"
            size="small"
            :rules="rules"
            :model="entity"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }">
      <a-form-item label="名称" name="name">
        <a-input v-model:value="entity.name"/>
      </a-form-item>

      <a-form-item label="锁定图" name="lockThumb">
        <a-space>
          <a-input v-model:value="entity.lockThumb"/>
          <a-button type="primary" @click="thumbPickCk('lockThumb')">选择</a-button>
        </a-space>
      </a-form-item>

      <a-form-item label="解锁图" name="unlockThumb">
        <a-space>
          <a-input v-model:value="entity.unlockThumb"/>
          <a-button type="primary" @click="thumbPickCk('unlockThumb')">选择</a-button>
        </a-space>
      </a-form-item>

      <a-form-item label="解锁" name="state">
        <a-switch v-model:checked="entity.state"/>
      </a-form-item>
    </a-form>
  </a-modal>

  <thumb-pick-modal ref="thumbPick" @choose="handleChoose"/>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

import ThumbPickModal from '@/components/ThumbPick'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const loading = ref(false)
const form = ref(null)
const thumbPick = ref(null)
const entity = ref({})

const rules = {
  name: [{
    required: true,
    message: '名称不能为空',
    trigger: 'blur'
  }],
  lockThumb: [{
    required: true,
    message: '锁定图不能为空',
    trigger: 'blur'
  }],
  unlockThumb: [{
    required: true,
    message: '解锁图不能为空',
    trigger: 'blur'
  }],
}

let itemId = 0

function openCk(pid, record) {
  itemId = pid

  entity.value = {
    id: record.id,
    name: record.name,
    lockThumb: record.lockThumb,
    unlockThumb: record.unlockThumb,
    state: (record.state === 2),
  }

  modal.value = true
}

function okCk() {
  loading.value = true

  form.value.validate().then(() => {
    let data = {...entity.value}
    data.state = (data.state ? 2 : 1)

    axios.put(`/item/${itemId}/attach/${entity.value.id}`, data).then((res) => {
      loading.value = false

      if (res.code === 1) {
        emits('refresh')
        modal.value = false
        form.value.resetFields()
      }
    })
  }).catch(() => {
    loading.value = false
  })
}

function cancelCk() {
  modal.value = false
  form.value.resetFields()
  loading.value = false
}

function thumbPickCk(key) {
  thumbPick.value.openCk(key)
}

function handleChoose(v, f) {
  if (f === 'lockThumb') {
    entity.value.lockThumb = v
    return
  }
  entity.value.unlockThumb = v
}

defineExpose({openCk})
</script>
