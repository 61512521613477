<template>
  <div class="nav">
    <a-typography-title :level="4">配置</a-typography-title>
    <a-space>
      <a-select class="long-select"
                :options="configList"
                v-model:value="k"
                show-search
                @change="query"/>
      <a-button type="primary" @click="addCk">添加</a-button>
      <a-button @click="initCk">初始化</a-button>
    </a-space>
  </div>

  <a-table
      size="small"
      :columns="columns"
      :data-source="source"
      :pagination="false"
      :loading="loading"
  >
    <template v-slot:bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'act'">
        <a-space>
          <a-button @click="updCk(record)" size="small">编辑</a-button>
          <a-button danger @click="delCk(record)" size="small">删除</a-button>
        </a-space>
      </template>

      <template v-else>
        {{ record[column.dataIndex] }}
      </template>
    </template>
  </a-table>

  <add-modal ref="add" @refresh="query"/>

  <upd-modal ref="upd" @refresh="query"/>
</template>

<script setup>
import {ref} from 'vue'
import {message, Modal} from 'ant-design-vue'
import axios from '@/axios'

import AddModal from '@/views/config/Add.vue'
import UpdModal from '@/views/config/Upd.vue'

const add = ref(null)
const upd = ref(null)
const source = ref([])
const configList = ref([])
const k = ref('')
const loading = ref(false)

const columns = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
  }, {
    key: 'k',
    title: '键',
    dataIndex: 'k',
  }, {
    key: 'v',
    title: '值',
    dataIndex: 'v',
  }, {
    key: 'act',
    title: '操作',
    dataIndex: 'act',
  }
]

function query() {
  loading.value = true

  axios.get('/config', {
    params: {
      k: k.value
    }
  }).then((res) => {
    loading.value = false
    if (res.code === 1) {
      source.value = res.data
    }
  })
}

function option() {
  axios.get('/config-opt').then((res) => {
    if (res.code === 1) {
      configList.value = res.data
      if (configList.value.length) {
        k.value = configList.value[0].value
      }
      query()
    }
  })
}

function initCk() {
  Modal.confirm({
    content: '确定初始化吗？',
    okText: '确定',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      axios.post(`/config/init`).then((res) => {
        if (res.code === 1) {
          query()
        }
      })
    },
  })
}

function addCk() {
  add.value.openCk()
}

function updCk(record) {
  upd.value.openCk(record)
}

function delCk(record) {
  Modal.confirm({
    content: '确定删除吗？',
    okText: '删除',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      axios.delete(`/config/${record.id}`).then((res) => {
        if (res.code === 1) {
          message.success('操作成功')
          query()
        }
      })
    },
  })
}

option()
</script>

<style scoped>
.long-select {
  width: 200px;
}
</style>