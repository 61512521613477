<template>
  <a-modal title="添加兑换码"
           :open="modal"
           :destroy-on-close="true"
           cancel-text="关闭" @cancel="cancelCk">
    <a-form size="small"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }">
      <a-form-item label="数量" name="num">
        <a-input-number min="1" v-model:value="num"/>
      </a-form-item>

    </a-form>
    <template #footer>
      <a-button type="primary" @click="okCk">添加</a-button>
    </template>
  </a-modal>
</template>

<script setup>
import {ref} from 'vue'
import axios from '@/axios'

const emits = defineEmits(['refresh'])

const modal = ref(false)
const num = ref(0)

function okCk() {
  axios.post(`/exchange`, {
    num: num.value
  }).then((res) => {
    if (res.code === 1) {
      emits('refresh')
      modal.value = false
    }
  })
}

function openCk() {
  modal.value = true
}

function cancelCk() {
  modal.value = false
}

defineExpose({openCk})
</script>